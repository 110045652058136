const showHMsResponse = `{
  status: 'success',
  results: 5,
  data: {
    docs: [
      {
        _id: '62f8d181cb1a94396dbd46b0',
        hm: '01',
        name: 'cut',
        type: 'normal',
        category: 'physical',
        pp: 30,
        power: 50,
        accuracy: 95,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd46b0',
      },
      {
        _id: '62f8d181cb1a94396dbd46c6',
        hm: '02',
        name: 'fly',
        type: 'flying',
        category: 'physical',
        pp: 15,
        power: 90,
        accuracy: 95,
        effect: 'flies up on first turn, attacks on second turn.',
        id: '62f8d181cb1a94396dbd46c6',
      },
      {
        _id: '62f8d181cb1a94396dbd4723',
        hm: '03',
        name: 'surf',
        type: 'water',
        category: 'special',
        pp: 15,
        power: 90,
        accuracy: 100,
        effect: 'hits all adjacent pokémon.',
        id: '62f8d181cb1a94396dbd4723',
      },
      {
        _id: '62f8d181cb1a94396dbd471b',
        hm: '04',
        name: 'strength',
        type: 'normal',
        category: 'physical',
        pp: 15,
        power: 80,
        accuracy: 100,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd471b',
      },
      {
        _id: '62f8d181cb1a94396dbd46c5',
        hm: '05',
        name: 'flash',
        type: 'normal',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: 100,
        effect: "lowers opponent's accuracy.",
        id: '62f8d181cb1a94396dbd46c5',
      },
    ],
  },
}`;

export default showHMsResponse;
