const showMovesResponse = `{
  status: 'success',
  results: 165,
  data: {
    data: [
      {
        _id: '62f8d181cb1a94396dbd4697',
        name: 'absorb',
        type: 'grass',
        category: 'special',
        pp: 25,
        power: 20,
        accuracy: 100,
        effect: 'user recovers half the hp inflicted on opponent.',
        id: '62f8d181cb1a94396dbd4697',
      },
      {
        _id: '62f8d181cb1a94396dbd4698',
        name: 'acid',
        type: 'poison',
        category: 'special',
        pp: 30,
        power: 40,
        accuracy: 100,
        effect: "may lower opponent's special defense.",
        id: '62f8d181cb1a94396dbd4698',
      },
      {
        _id: '62f8d181cb1a94396dbd4699',
        name: 'acid armor',
        type: 'poison',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: null,
        effect: "sharply raises user's defense.",
        id: '62f8d181cb1a94396dbd4699',
      },
      {
        _id: '62f8d181cb1a94396dbd469a',
        name: 'agility',
        type: 'psychic',
        category: 'status',
        pp: 30,
        power: null,
        accuracy: null,
        effect: "sharply raises user's speed.",
        id: '62f8d181cb1a94396dbd469a',
      },
      {
        _id: '62f8d181cb1a94396dbd469b',
        name: 'amnesia',
        type: 'psychic',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: null,
        effect: "sharply raises user's special defense.",
        id: '62f8d181cb1a94396dbd469b',
      },
      {
        _id: '62f8d181cb1a94396dbd469c',
        name: 'aurora beam',
        type: 'ice',
        category: 'special',
        pp: 20,
        power: 65,
        accuracy: 100,
        effect: "may lower opponent's attack.",
        id: '62f8d181cb1a94396dbd469c',
      },
      {
        _id: '62f8d181cb1a94396dbd469d',
        name: 'barrage',
        type: 'normal',
        category: 'physical',
        pp: 20,
        power: 15,
        accuracy: 85,
        effect: 'hits 2-5 times in one turn.',
        id: '62f8d181cb1a94396dbd469d',
      },
      {
        _id: '62f8d181cb1a94396dbd469e',
        name: 'barrier',
        type: 'psychic',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: null,
        effect: "sharply raises user's defense.",
        id: '62f8d181cb1a94396dbd469e',
      },
      {
        _id: '62f8d181cb1a94396dbd469f',
        tm: '34',
        name: 'bide',
        type: 'normal',
        category: 'physical',
        pp: 10,
        power: null,
        accuracy: null,
        effect: 'user takes damage for two turns then strikes back double.',
        id: '62f8d181cb1a94396dbd469f',
      },
      {
        _id: '62f8d181cb1a94396dbd46a0',
        name: 'bind',
        type: 'normal',
        category: 'physical',
        pp: 20,
        power: 15,
        accuracy: 85,
        effect: 'traps opponent, damaging them for 4-5 turns.',
        id: '62f8d181cb1a94396dbd46a0',
      },
      {
        _id: '62f8d181cb1a94396dbd46a1',
        name: 'bite',
        type: 'dark',
        category: 'physical',
        pp: 25,
        power: 60,
        accuracy: 100,
        effect: 'may cause flinching.',
        id: '62f8d181cb1a94396dbd46a1',
      },
      {
        _id: '62f8d181cb1a94396dbd46a2',
        tm: '14',
        name: 'blizzard',
        type: 'ice',
        category: 'special',
        pp: 5,
        power: 110,
        accuracy: 70,
        effect: 'may freeze opponent.',
        id: '62f8d181cb1a94396dbd46a2',
      },
      {
        _id: '62f8d181cb1a94396dbd46a3',
        tm: '08',
        name: 'body slam',
        type: 'normal',
        category: 'physical',
        pp: 15,
        power: 85,
        accuracy: 100,
        effect: 'may paralyze opponent.',
        id: '62f8d181cb1a94396dbd46a3',
      },
      {
        _id: '62f8d181cb1a94396dbd46a4',
        name: 'bone club',
        type: 'ground',
        category: 'physical',
        pp: 20,
        power: 65,
        accuracy: 85,
        effect: 'may cause flinching.',
        id: '62f8d181cb1a94396dbd46a4',
      },
      {
        _id: '62f8d181cb1a94396dbd46a5',
        name: 'bonemerang',
        type: 'ground',
        category: 'physical',
        pp: 10,
        power: 50,
        accuracy: 90,
        effect: 'hits twice in one turn.',
        id: '62f8d181cb1a94396dbd46a5',
      },
      {
        _id: '62f8d181cb1a94396dbd46a6',
        name: 'bubble',
        type: 'water',
        category: 'special',
        pp: 30,
        power: 40,
        accuracy: 100,
        effect: "may lower opponent's speed.",
        id: '62f8d181cb1a94396dbd46a6',
      },
      {
        _id: '62f8d181cb1a94396dbd46a7',
        tm: '11',
        name: 'bubble beam',
        type: 'water',
        category: 'special',
        pp: 20,
        power: 65,
        accuracy: 100,
        effect: "may lower opponent's speed.",
        id: '62f8d181cb1a94396dbd46a7',
      },
      {
        _id: '62f8d181cb1a94396dbd46a8',
        name: 'clamp',
        type: 'water',
        category: 'physical',
        pp: 15,
        power: 35,
        accuracy: 85,
        effect: 'traps opponent, damaging them for 4-5 turns.',
        id: '62f8d181cb1a94396dbd46a8',
      },
      {
        _id: '62f8d181cb1a94396dbd46a9',
        name: 'comet punch',
        type: 'normal',
        category: 'physical',
        pp: 15,
        power: 18,
        accuracy: 85,
        effect: 'hits 2-5 times in one turn.',
        id: '62f8d181cb1a94396dbd46a9',
      },
      {
        _id: '62f8d181cb1a94396dbd46aa',
        name: 'confuse ray',
        type: 'ghost',
        category: 'status',
        pp: 10,
        power: null,
        accuracy: 100,
        effect: 'confuses opponent.',
        id: '62f8d181cb1a94396dbd46aa',
      },
      {
        _id: '62f8d181cb1a94396dbd46ab',
        name: 'confusion',
        type: 'psychic',
        category: 'special',
        pp: 25,
        power: 50,
        accuracy: 100,
        effect: 'may confuse opponent.',
        id: '62f8d181cb1a94396dbd46ab',
      },
      {
        _id: '62f8d181cb1a94396dbd46ac',
        name: 'constrict',
        type: 'normal',
        category: 'physical',
        pp: 35,
        power: 10,
        accuracy: 100,
        effect: "may lower opponent's speed by one stage.",
        id: '62f8d181cb1a94396dbd46ac',
      },
      {
        _id: '62f8d181cb1a94396dbd46ad',
        name: 'conversion',
        type: 'normal',
        category: 'status',
        pp: 30,
        power: null,
        accuracy: null,
        effect: "changes user's type to that of its first move.",
        id: '62f8d181cb1a94396dbd46ad',
      },
      {
        _id: '62f8d181cb1a94396dbd46ae',
        tm: '18',
        name: 'counter',
        type: 'fighting',
        category: 'physical',
        pp: 20,
        power: null,
        accuracy: 100,
        effect: 'when hit by a physical attack, user strikes back with 2x power.',
        id: '62f8d181cb1a94396dbd46ae',
      },
      {
        _id: '62f8d181cb1a94396dbd46af',
        name: 'crabhammer',
        type: 'water',
        category: 'physical',
        pp: 10,
        power: 100,
        accuracy: 90,
        effect: 'high critical hit ratio.',
        id: '62f8d181cb1a94396dbd46af',
      },
      {
        _id: '62f8d181cb1a94396dbd46b0',
        hm: '01',
        name: 'cut',
        type: 'normal',
        category: 'physical',
        pp: 30,
        power: 50,
        accuracy: 95,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd46b0',
      },
      {
        _id: '62f8d181cb1a94396dbd46b1',
        name: 'defense curl',
        type: 'normal',
        category: 'status',
        pp: 40,
        power: null,
        accuracy: null,
        effect: "raises user's defense.",
        id: '62f8d181cb1a94396dbd46b1',
      },
      {
        _id: '62f8d181cb1a94396dbd46b2',
        tm: '28',
        name: 'dig',
        type: 'ground',
        category: 'physical',
        pp: 10,
        power: 80,
        accuracy: 100,
        effect:
          'digs underground on first turn, attacks on second. can also escape from caves.',
        id: '62f8d181cb1a94396dbd46b2',
      },
      {
        _id: '62f8d181cb1a94396dbd46b3',
        name: 'disable',
        type: 'normal',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: 100,
        effect: "opponent can't use its last attack for a few turns.",
        id: '62f8d181cb1a94396dbd46b3',
      },
      {
        _id: '62f8d181cb1a94396dbd46b4',
        name: 'dizzy punch',
        type: 'normal',
        category: 'physical',
        pp: 10,
        power: 70,
        accuracy: 100,
        effect: 'may confuse opponent.',
        id: '62f8d181cb1a94396dbd46b4',
      },
      {
        _id: '62f8d181cb1a94396dbd46b5',
        name: 'double kick',
        type: 'fighting',
        category: 'physical',
        pp: 30,
        power: 30,
        accuracy: 100,
        effect: 'hits twice in one turn.',
        id: '62f8d181cb1a94396dbd46b5',
      },
      {
        _id: '62f8d181cb1a94396dbd46b6',
        name: 'double slap',
        type: 'normal',
        category: 'physical',
        pp: 10,
        power: 15,
        accuracy: 85,
        effect: 'hits 2-5 times in one turn.',
        id: '62f8d181cb1a94396dbd46b6',
      },
      {
        _id: '62f8d181cb1a94396dbd46b7',
        tm: '32',
        name: 'double team',
        type: 'normal',
        category: 'status',
        pp: 15,
        power: null,
        accuracy: null,
        effect: "raises user's evasiveness.",
        id: '62f8d181cb1a94396dbd46b7',
      },
      {
        _id: '62f8d181cb1a94396dbd46b8',
        tm: '10',
        name: 'double-edge',
        type: 'normal',
        category: 'physical',
        pp: 15,
        power: 120,
        accuracy: 100,
        effect: 'user receives recoil damage.',
        id: '62f8d181cb1a94396dbd46b8',
      },
      {
        _id: '62f8d181cb1a94396dbd46b9',
        tm: '23',
        name: 'dragon rage',
        type: 'dragon',
        category: 'special',
        pp: 10,
        power: null,
        accuracy: 100,
        effect: 'always inflicts 40 hp.',
        id: '62f8d181cb1a94396dbd46b9',
      },
      {
        _id: '62f8d181cb1a94396dbd46ba',
        tm: '42',
        name: 'dream eater',
        type: 'psychic',
        category: 'special',
        pp: 15,
        power: 100,
        accuracy: 100,
        effect: 'user recovers half the hp inflicted on a sleeping opponent.',
        id: '62f8d181cb1a94396dbd46ba',
      },
      {
        _id: '62f8d181cb1a94396dbd46bb',
        name: 'drill peck',
        type: 'flying',
        category: 'physical',
        pp: 20,
        power: 80,
        accuracy: 100,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd46bb',
      },
      {
        _id: '62f8d181cb1a94396dbd46bc',
        tm: '26',
        name: 'earthquake',
        type: 'ground',
        category: 'physical',
        pp: 10,
        power: 100,
        accuracy: 100,
        effect: 'power is doubled if opponent is underground from using dig.',
        id: '62f8d181cb1a94396dbd46bc',
      },
      {
        _id: '62f8d181cb1a94396dbd46bd',
        tm: '37',
        name: 'egg bomb',
        type: 'normal',
        category: 'physical',
        pp: 10,
        power: 100,
        accuracy: 75,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd46bd',
      },
      {
        _id: '62f8d181cb1a94396dbd46be',
        name: 'ember',
        type: 'fire',
        category: 'special',
        pp: 25,
        power: 40,
        accuracy: 100,
        effect: 'may burn opponent.',
        id: '62f8d181cb1a94396dbd46be',
      },
      {
        _id: '62f8d181cb1a94396dbd46bf',
        tm: '47',
        name: 'explosion',
        type: 'normal',
        category: 'physical',
        pp: 5,
        power: 250,
        accuracy: 100,
        effect: 'user faints.',
        id: '62f8d181cb1a94396dbd46bf',
      },
      {
        _id: '62f8d181cb1a94396dbd46c0',
        tm: '38',
        name: 'fire blast',
        type: 'fire',
        category: 'special',
        pp: 5,
        power: 110,
        accuracy: 85,
        effect: 'may burn opponent.',
        id: '62f8d181cb1a94396dbd46c0',
      },
      {
        _id: '62f8d181cb1a94396dbd46c1',
        name: 'fire punch',
        type: 'fire',
        category: 'physical',
        pp: 15,
        power: 75,
        accuracy: 100,
        effect: 'may burn opponent.',
        id: '62f8d181cb1a94396dbd46c1',
      },
      {
        _id: '62f8d181cb1a94396dbd46c2',
        name: 'fire spin',
        type: 'fire',
        category: 'special',
        pp: 15,
        power: 35,
        accuracy: 85,
        effect: 'traps opponent, damaging them for 4-5 turns.',
        id: '62f8d181cb1a94396dbd46c2',
      },
      {
        _id: '62f8d181cb1a94396dbd46c3',
        tm: '27',
        name: 'fissure',
        type: 'ground',
        category: 'physical',
        pp: 5,
        power: null,
        accuracy: 30,
        effect: 'one-hit-ko, if it hits.',
        id: '62f8d181cb1a94396dbd46c3',
      },
      {
        _id: '62f8d181cb1a94396dbd46c4',
        name: 'flamethrower',
        type: 'fire',
        category: 'special',
        pp: 15,
        power: 90,
        accuracy: 100,
        effect: 'may burn opponent.',
        id: '62f8d181cb1a94396dbd46c4',
      },
      {
        _id: '62f8d181cb1a94396dbd46c5',
        hm: '05',
        name: 'flash',
        type: 'normal',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: 100,
        effect: "lowers opponent's accuracy.",
        id: '62f8d181cb1a94396dbd46c5',
      },
      {
        _id: '62f8d181cb1a94396dbd46c6',
        hm: '02',
        name: 'fly',
        type: 'flying',
        category: 'physical',
        pp: 15,
        power: 90,
        accuracy: 95,
        effect: 'flies up on first turn, attacks on second turn.',
        id: '62f8d181cb1a94396dbd46c6',
      },
      {
        _id: '62f8d181cb1a94396dbd46c7',
        name: 'focus energy',
        type: 'normal',
        category: 'status',
        pp: 30,
        power: null,
        accuracy: null,
        effect: 'increases critical hit ratio.',
        id: '62f8d181cb1a94396dbd46c7',
      },
      {
        _id: '62f8d181cb1a94396dbd46c8',
        name: 'fury attack',
        type: 'normal',
        category: 'physical',
        pp: 20,
        power: 15,
        accuracy: 85,
        effect: 'hits 2-5 times in one turn.',
        id: '62f8d181cb1a94396dbd46c8',
      },
      {
        _id: '62f8d181cb1a94396dbd46c9',
        name: 'fury swipes',
        type: 'normal',
        category: 'physical',
        pp: 15,
        power: 18,
        accuracy: 80,
        effect: 'hits 2-5 times in one turn.',
        id: '62f8d181cb1a94396dbd46c9',
      },
      {
        _id: '62f8d181cb1a94396dbd46ca',
        name: 'glare',
        type: 'normal',
        category: 'status',
        pp: 30,
        power: null,
        accuracy: 100,
        effect: 'paralyzes opponent.',
        id: '62f8d181cb1a94396dbd46ca',
      },
      {
        _id: '62f8d181cb1a94396dbd46cb',
        name: 'growl',
        type: 'normal',
        category: 'status',
        pp: 40,
        power: null,
        accuracy: 100,
        effect: "lowers opponent's attack.",
        id: '62f8d181cb1a94396dbd46cb',
      },
      {
        _id: '62f8d181cb1a94396dbd46cc',
        name: 'growth',
        type: 'normal',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: null,
        effect: "raises user's attack and special attack.",
        id: '62f8d181cb1a94396dbd46cc',
      },
      {
        _id: '62f8d181cb1a94396dbd46cd',
        name: 'guillotine',
        type: 'normal',
        category: 'physical',
        pp: 5,
        power: null,
        accuracy: 30,
        effect: 'one-hit-ko, if it hits.',
        id: '62f8d181cb1a94396dbd46cd',
      },
      {
        _id: '62f8d181cb1a94396dbd46ce',
        name: 'gust',
        type: 'flying',
        category: 'special',
        pp: 35,
        power: 40,
        accuracy: 100,
        effect: 'hits pokémon using fly/bounce with double power.',
        id: '62f8d181cb1a94396dbd46ce',
      },
      {
        _id: '62f8d181cb1a94396dbd46cf',
        name: 'harden',
        type: 'normal',
        category: 'status',
        pp: 30,
        power: null,
        accuracy: null,
        effect: "raises user's defense.",
        id: '62f8d181cb1a94396dbd46cf',
      },
      {
        _id: '62f8d181cb1a94396dbd46d0',
        name: 'haze',
        type: 'ice',
        category: 'status',
        pp: 30,
        power: null,
        accuracy: null,
        effect: 'resets all stat changes.',
        id: '62f8d181cb1a94396dbd46d0',
      },
      {
        _id: '62f8d181cb1a94396dbd46d1',
        name: 'headbutt',
        type: 'normal',
        category: 'physical',
        pp: 15,
        power: 70,
        accuracy: 100,
        effect: 'may cause flinching.',
        id: '62f8d181cb1a94396dbd46d1',
      },
      {
        _id: '62f8d181cb1a94396dbd46d2',
        name: 'high jump kick',
        type: 'fighting',
        category: 'physical',
        pp: 10,
        power: 130,
        accuracy: 90,
        effect: 'if it misses, the user loses half their hp.',
        id: '62f8d181cb1a94396dbd46d2',
      },
      {
        _id: '62f8d181cb1a94396dbd46d3',
        name: 'horn attack',
        type: 'normal',
        category: 'physical',
        pp: 25,
        power: 65,
        accuracy: 100,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd46d3',
      },
      {
        _id: '62f8d181cb1a94396dbd46d4',
        tm: '07',
        name: 'horn drill',
        type: 'normal',
        category: 'physical',
        pp: 5,
        power: null,
        accuracy: 30,
        effect: 'one-hit-ko, if it hits.',
        id: '62f8d181cb1a94396dbd46d4',
      },
      {
        _id: '62f8d181cb1a94396dbd46d5',
        name: 'hydro pump',
        type: 'water',
        category: 'special',
        pp: 5,
        power: 110,
        accuracy: 80,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd46d5',
      },
      {
        _id: '62f8d181cb1a94396dbd46d6',
        tm: '15',
        name: 'hyper beam',
        type: 'normal',
        category: 'special',
        pp: 5,
        power: 150,
        accuracy: 90,
        effect: 'user must recharge next turn.',
        id: '62f8d181cb1a94396dbd46d6',
      },
      {
        _id: '62f8d181cb1a94396dbd46d7',
        name: 'hyper fang',
        type: 'normal',
        category: 'physical',
        pp: 15,
        power: 80,
        accuracy: 90,
        effect: 'may cause flinching.',
        id: '62f8d181cb1a94396dbd46d7',
      },
      {
        _id: '62f8d181cb1a94396dbd46d8',
        name: 'hypnosis',
        type: 'psychic',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: 60,
        effect: 'puts opponent to sleep.',
        id: '62f8d181cb1a94396dbd46d8',
      },
      {
        _id: '62f8d181cb1a94396dbd46d9',
        tm: '13',
        name: 'ice beam',
        type: 'ice',
        category: 'special',
        pp: 10,
        power: 90,
        accuracy: 100,
        effect: 'may freeze opponent.',
        id: '62f8d181cb1a94396dbd46d9',
      },
      {
        _id: '62f8d181cb1a94396dbd46da',
        name: 'ice punch',
        type: 'ice',
        category: 'physical',
        pp: 15,
        power: 75,
        accuracy: 100,
        effect: 'may freeze opponent.',
        id: '62f8d181cb1a94396dbd46da',
      },
      {
        _id: '62f8d181cb1a94396dbd46db',
        name: 'jump kick',
        type: 'fighting',
        category: 'physical',
        pp: 10,
        power: 100,
        accuracy: 95,
        effect: 'if it misses, the user loses half their hp.',
        id: '62f8d181cb1a94396dbd46db',
      },
      {
        _id: '62f8d181cb1a94396dbd46dc',
        name: 'karate chop',
        type: 'fighting',
        category: 'physical',
        pp: 25,
        power: 50,
        accuracy: 100,
        effect: 'high critical hit ratio.',
        id: '62f8d181cb1a94396dbd46dc',
      },
      {
        _id: '62f8d181cb1a94396dbd46dd',
        name: 'kinesis',
        type: 'psychic',
        category: 'status',
        pp: 15,
        power: null,
        accuracy: 80,
        effect: "lowers opponent's accuracy.",
        id: '62f8d181cb1a94396dbd46dd',
      },
      {
        _id: '62f8d181cb1a94396dbd46de',
        name: 'leech life',
        type: 'bug',
        category: 'physical',
        pp: 10,
        power: 80,
        accuracy: 100,
        effect: 'user recovers half the hp inflicted on opponent.',
        id: '62f8d181cb1a94396dbd46de',
      },
      {
        _id: '62f8d181cb1a94396dbd46df',
        name: 'leech seed',
        type: 'grass',
        category: 'status',
        pp: 10,
        power: null,
        accuracy: 90,
        effect: 'drains hp from opponent each turn.',
        id: '62f8d181cb1a94396dbd46df',
      },
      {
        _id: '62f8d181cb1a94396dbd46e0',
        name: 'leer',
        type: 'normal',
        category: 'status',
        pp: 30,
        power: null,
        accuracy: 100,
        effect: "lowers opponent's defense.",
        id: '62f8d181cb1a94396dbd46e0',
      },
      {
        _id: '62f8d181cb1a94396dbd46e1',
        name: 'lick',
        type: 'ghost',
        category: 'physical',
        pp: 30,
        power: 30,
        accuracy: 100,
        effect: 'may paralyze opponent.',
        id: '62f8d181cb1a94396dbd46e1',
      },
      {
        _id: '62f8d181cb1a94396dbd46e2',
        name: 'light screen',
        type: 'psychic',
        category: 'status',
        pp: 30,
        power: null,
        accuracy: null,
        effect: 'halves damage from special attacks for 5 turns.',
        id: '62f8d181cb1a94396dbd46e2',
      },
      {
        _id: '62f8d181cb1a94396dbd46e3',
        name: 'lovely kiss',
        type: 'normal',
        category: 'status',
        pp: 10,
        power: null,
        accuracy: 75,
        effect: 'puts opponent to sleep.',
        id: '62f8d181cb1a94396dbd46e3',
      },
      {
        _id: '62f8d181cb1a94396dbd46e4',
        name: 'low kick',
        type: 'fighting',
        category: 'physical',
        pp: 20,
        power: null,
        accuracy: 100,
        effect: 'the heavier the opponent, the stronger the attack.',
        id: '62f8d181cb1a94396dbd46e4',
      },
      {
        _id: '62f8d181cb1a94396dbd46e5',
        name: 'meditate',
        type: 'psychic',
        category: 'status',
        pp: 40,
        power: null,
        accuracy: null,
        effect: "raises user's attack.",
        id: '62f8d181cb1a94396dbd46e5',
      },
      {
        _id: '62f8d181cb1a94396dbd46e6',
        tm: '21',
        name: 'mega drain',
        type: 'grass',
        category: 'special',
        pp: 15,
        power: 40,
        accuracy: 100,
        effect: 'user recovers half the hp inflicted on opponent.',
        id: '62f8d181cb1a94396dbd46e6',
      },
      {
        _id: '62f8d181cb1a94396dbd46e7',
        tm: '05',
        name: 'mega kick',
        type: 'normal',
        category: 'physical',
        pp: 5,
        power: 120,
        accuracy: 75,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd46e7',
      },
      {
        _id: '62f8d181cb1a94396dbd46e8',
        tm: '01',
        name: 'mega punch',
        type: 'normal',
        category: 'physical',
        pp: 20,
        power: 80,
        accuracy: 85,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd46e8',
      },
      {
        _id: '62f8d181cb1a94396dbd46e9',
        tm: '35',
        name: 'metronome',
        type: 'normal',
        category: 'status',
        pp: 10,
        power: null,
        accuracy: null,
        effect: 'user performs almost any move in the game at random.',
        id: '62f8d181cb1a94396dbd46e9',
      },
      {
        _id: '62f8d181cb1a94396dbd46ea',
        tm: '31',
        name: 'mimic',
        type: 'normal',
        category: 'status',
        pp: 10,
        power: null,
        accuracy: null,
        effect: "copies the opponent's last move.",
        id: '62f8d181cb1a94396dbd46ea',
      },
      {
        _id: '62f8d181cb1a94396dbd46eb',
        name: 'minimize',
        type: 'normal',
        category: 'status',
        pp: 10,
        power: null,
        accuracy: null,
        effect: "sharply raises user's evasiveness.",
        id: '62f8d181cb1a94396dbd46eb',
      },
      {
        _id: '62f8d181cb1a94396dbd46ec',
        name: 'mirror move',
        type: 'flying',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: null,
        effect: "user performs the opponent's last move.",
        id: '62f8d181cb1a94396dbd46ec',
      },
      {
        _id: '62f8d181cb1a94396dbd46ed',
        name: 'mist',
        type: 'ice',
        category: 'status',
        pp: 30,
        power: null,
        accuracy: null,
        effect: "user's stats cannot be changed for a period of time.",
        id: '62f8d181cb1a94396dbd46ed',
      },
      {
        _id: '62f8d181cb1a94396dbd46ee',
        name: 'night shade',
        type: 'ghost',
        category: 'special',
        pp: 15,
        power: null,
        accuracy: 100,
        effect: "inflicts damage equal to user's level.",
        id: '62f8d181cb1a94396dbd46ee',
      },
      {
        _id: '62f8d181cb1a94396dbd46ef',
        tm: '16',
        name: 'pay day',
        type: 'normal',
        category: 'physical',
        pp: 20,
        power: 40,
        accuracy: 100,
        effect: 'a small amount of money is gained after the battle resolves.',
        id: '62f8d181cb1a94396dbd46ef',
      },
      {
        _id: '62f8d181cb1a94396dbd46f0',
        name: 'peck',
        type: 'flying',
        category: 'physical',
        pp: 35,
        power: 35,
        accuracy: 100,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd46f0',
      },
      {
        _id: '62f8d181cb1a94396dbd46f1',
        name: 'petal dance',
        type: 'grass',
        category: 'special',
        pp: 10,
        power: 120,
        accuracy: 100,
        effect: 'user attacks for 2-3 turns but then becomes confused.',
        id: '62f8d181cb1a94396dbd46f1',
      },
      {
        _id: '62f8d181cb1a94396dbd46f2',
        name: 'pin missile',
        type: 'bug',
        category: 'physical',
        pp: 20,
        power: 25,
        accuracy: 95,
        effect: 'hits 2-5 times in one turn.',
        id: '62f8d181cb1a94396dbd46f2',
      },
      {
        _id: '62f8d181cb1a94396dbd46f3',
        name: 'poison gas',
        type: 'poison',
        category: 'status',
        pp: 40,
        power: null,
        accuracy: 90,
        effect: 'poisons opponent.',
        id: '62f8d181cb1a94396dbd46f3',
      },
      {
        _id: '62f8d181cb1a94396dbd46f4',
        name: 'poison powder',
        type: 'poison',
        category: 'status',
        pp: 35,
        power: null,
        accuracy: 75,
        effect: 'poisons opponent.',
        id: '62f8d181cb1a94396dbd46f4',
      },
      {
        _id: '62f8d181cb1a94396dbd46f5',
        name: 'poison sting',
        type: 'poison',
        category: 'physical',
        pp: 35,
        power: 15,
        accuracy: 100,
        effect: 'may poison the opponent.',
        id: '62f8d181cb1a94396dbd46f5',
      },
      {
        _id: '62f8d181cb1a94396dbd46f6',
        name: 'pound',
        type: 'normal',
        category: 'physical',
        pp: 35,
        power: 40,
        accuracy: 100,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd46f6',
      },
      {
        _id: '62f8d181cb1a94396dbd46f7',
        name: 'psybeam',
        type: 'psychic',
        category: 'special',
        pp: 20,
        power: 65,
        accuracy: 100,
        effect: 'may confuse opponent.',
        id: '62f8d181cb1a94396dbd46f7',
      },
      {
        _id: '62f8d181cb1a94396dbd46f8',
        tm: '29',
        name: 'psychic',
        type: 'psychic',
        category: 'special',
        pp: 10,
        power: 90,
        accuracy: 100,
        effect: "may lower opponent's special defense.",
        id: '62f8d181cb1a94396dbd46f8',
      },
      {
        _id: '62f8d181cb1a94396dbd46f9',
        tm: '46',
        name: 'psywave',
        type: 'psychic',
        category: 'special',
        pp: 15,
        power: null,
        accuracy: 100,
        effect: "inflicts damage 50-150% of user's level.",
        id: '62f8d181cb1a94396dbd46f9',
      },
      {
        _id: '62f8d181cb1a94396dbd46fa',
        name: 'quick attack',
        type: 'normal',
        category: 'physical',
        pp: 30,
        power: 40,
        accuracy: 100,
        effect: 'user attacks first.',
        id: '62f8d181cb1a94396dbd46fa',
      },
      {
        _id: '62f8d181cb1a94396dbd46fb',
        tm: '20',
        name: 'rage',
        type: 'normal',
        category: 'physical',
        pp: 20,
        power: 20,
        accuracy: 100,
        effect: "raises user's attack when hit.",
        id: '62f8d181cb1a94396dbd46fb',
      },
      {
        _id: '62f8d181cb1a94396dbd46fc',
        name: 'razor leaf',
        type: 'grass',
        category: 'physical',
        pp: 25,
        power: 55,
        accuracy: 95,
        effect: 'high critical hit ratio.',
        id: '62f8d181cb1a94396dbd46fc',
      },
      {
        _id: '62f8d181cb1a94396dbd46fd',
        tm: '02',
        name: 'razor wind',
        type: 'normal',
        category: 'special',
        pp: 10,
        power: 80,
        accuracy: 100,
        effect: 'charges on first turn, attacks on second. high critical hit ratio.',
        id: '62f8d181cb1a94396dbd46fd',
      },
      {
        _id: '62f8d181cb1a94396dbd46fe',
        name: 'recover',
        type: 'normal',
        category: 'status',
        pp: 10,
        power: null,
        accuracy: null,
        effect: 'user recovers half its max hp.',
        id: '62f8d181cb1a94396dbd46fe',
      },
      {
        _id: '62f8d181cb1a94396dbd46ff',
        tm: '33',
        name: 'reflect',
        type: 'psychic',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: null,
        effect: 'halves damage from physical attacks for 5 turns.',
        id: '62f8d181cb1a94396dbd46ff',
      },
      {
        _id: '62f8d181cb1a94396dbd4700',
        tm: '44',
        name: 'rest',
        type: 'psychic',
        category: 'status',
        pp: 10,
        power: null,
        accuracy: null,
        effect: 'user sleeps for 2 turns, but user is fully healed.',
        id: '62f8d181cb1a94396dbd4700',
      },
      {
        _id: '62f8d181cb1a94396dbd4701',
        name: 'roar',
        type: 'normal',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: null,
        effect: 'in battles, the opponent switches. in the wild, the pokémon runs.',
        id: '62f8d181cb1a94396dbd4701',
      },
      {
        _id: '62f8d181cb1a94396dbd4702',
        tm: '48',
        name: 'rock slide',
        type: 'rock',
        category: 'physical',
        pp: 10,
        power: 75,
        accuracy: 90,
        effect: 'may cause flinching.',
        id: '62f8d181cb1a94396dbd4702',
      },
      {
        _id: '62f8d181cb1a94396dbd4703',
        name: 'rock throw',
        type: 'rock',
        category: 'physical',
        pp: 15,
        power: 50,
        accuracy: 90,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd4703',
      },
      {
        _id: '62f8d181cb1a94396dbd4704',
        name: 'rolling kick',
        type: 'fighting',
        category: 'physical',
        pp: 15,
        power: 60,
        accuracy: 85,
        effect: 'may cause flinching.',
        id: '62f8d181cb1a94396dbd4704',
      },
      {
        _id: '62f8d181cb1a94396dbd4705',
        name: 'sand attack',
        type: 'ground',
        category: 'status',
        pp: 15,
        power: null,
        accuracy: 100,
        effect: "lowers opponent's accuracy.",
        id: '62f8d181cb1a94396dbd4705',
      },
      {
        _id: '62f8d181cb1a94396dbd4706',
        name: 'scratch',
        type: 'normal',
        category: 'physical',
        pp: 35,
        power: 40,
        accuracy: 100,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd4706',
      },
      {
        _id: '62f8d181cb1a94396dbd4707',
        name: 'screech',
        type: 'normal',
        category: 'status',
        pp: 40,
        power: null,
        accuracy: 85,
        effect: "sharply lowers opponent's defense.",
        id: '62f8d181cb1a94396dbd4707',
      },
      {
        _id: '62f8d181cb1a94396dbd4708',
        tm: '19',
        name: 'seismic toss',
        type: 'fighting',
        category: 'physical',
        pp: 20,
        power: null,
        accuracy: 100,
        effect: "inflicts damage equal to user's level.",
        id: '62f8d181cb1a94396dbd4708',
      },
      {
        _id: '62f8d181cb1a94396dbd4709',
        tm: '36',
        name: 'self-destruct',
        type: 'normal',
        category: 'physical',
        pp: 5,
        power: 200,
        accuracy: 100,
        effect: 'user faints.',
        id: '62f8d181cb1a94396dbd4709',
      },
      {
        _id: '62f8d181cb1a94396dbd470a',
        name: 'sharpen',
        type: 'normal',
        category: 'status',
        pp: 30,
        power: null,
        accuracy: null,
        effect: "raises user's attack.",
        id: '62f8d181cb1a94396dbd470a',
      },
      {
        _id: '62f8d181cb1a94396dbd470b',
        name: 'sing',
        type: 'normal',
        category: 'status',
        pp: 15,
        power: null,
        accuracy: 55,
        effect: 'puts opponent to sleep.',
        id: '62f8d181cb1a94396dbd470b',
      },
      {
        _id: '62f8d181cb1a94396dbd470c',
        tm: '40',
        name: 'skull bash',
        type: 'normal',
        category: 'physical',
        pp: 10,
        power: 130,
        accuracy: 100,
        effect: 'raises defense on first turn, attacks on second.',
        id: '62f8d181cb1a94396dbd470c',
      },
      {
        _id: '62f8d181cb1a94396dbd470d',
        tm: '43',
        name: 'sky attack',
        type: 'flying',
        category: 'physical',
        pp: 5,
        power: 140,
        accuracy: 90,
        effect:
          'charges on first turn, attacks on second. may cause flinching. high critical hit ratio.',
        id: '62f8d181cb1a94396dbd470d',
      },
      {
        _id: '62f8d181cb1a94396dbd470e',
        name: 'slam',
        type: 'normal',
        category: 'physical',
        pp: 20,
        power: 80,
        accuracy: 75,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd470e',
      },
      {
        _id: '62f8d181cb1a94396dbd470f',
        name: 'slash',
        type: 'normal',
        category: 'physical',
        pp: 20,
        power: 70,
        accuracy: 100,
        effect: 'high critical hit ratio.',
        id: '62f8d181cb1a94396dbd470f',
      },
      {
        _id: '62f8d181cb1a94396dbd4710',
        name: 'sleep powder',
        type: 'grass',
        category: 'status',
        pp: 15,
        power: null,
        accuracy: 75,
        effect: 'puts opponent to sleep.',
        id: '62f8d181cb1a94396dbd4710',
      },
      {
        _id: '62f8d181cb1a94396dbd4711',
        name: 'sludge',
        type: 'poison',
        category: 'special',
        pp: 20,
        power: 65,
        accuracy: 100,
        effect: 'may poison opponent.',
        id: '62f8d181cb1a94396dbd4711',
      },
      {
        _id: '62f8d181cb1a94396dbd4712',
        name: 'smog',
        type: 'poison',
        category: 'special',
        pp: 20,
        power: 30,
        accuracy: 70,
        effect: 'may poison opponent.',
        id: '62f8d181cb1a94396dbd4712',
      },
      {
        _id: '62f8d181cb1a94396dbd4713',
        name: 'smokescreen',
        type: 'normal',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: 100,
        effect: "lowers opponent's accuracy.",
        id: '62f8d181cb1a94396dbd4713',
      },
      {
        _id: '62f8d181cb1a94396dbd4714',
        tm: '41',
        name: 'soft-boiled',
        type: 'normal',
        category: 'status',
        pp: 10,
        power: null,
        accuracy: null,
        effect: 'user recovers half its max hp.',
        id: '62f8d181cb1a94396dbd4714',
      },
      {
        _id: '62f8d181cb1a94396dbd4715',
        tm: '22',
        name: 'solar beam',
        type: 'grass',
        category: 'special',
        pp: 10,
        power: 120,
        accuracy: 100,
        effect: 'charges on first turn, attacks on second.',
        id: '62f8d181cb1a94396dbd4715',
      },
      {
        _id: '62f8d181cb1a94396dbd4716',
        name: 'sonic boom',
        type: 'normal',
        category: 'special',
        pp: 20,
        power: null,
        accuracy: 90,
        effect: 'always inflicts 20 hp.',
        id: '62f8d181cb1a94396dbd4716',
      },
      {
        _id: '62f8d181cb1a94396dbd4717',
        name: 'spike cannon',
        type: 'normal',
        category: 'physical',
        pp: 15,
        power: 20,
        accuracy: 100,
        effect: 'hits 2-5 times in one turn.',
        id: '62f8d181cb1a94396dbd4717',
      },
      {
        _id: '62f8d181cb1a94396dbd4718',
        name: 'splash',
        type: 'normal',
        category: 'status',
        pp: 40,
        power: null,
        accuracy: null,
        effect: "doesn't do anything.",
        id: '62f8d181cb1a94396dbd4718',
      },
      {
        _id: '62f8d181cb1a94396dbd4719',
        name: 'spore',
        type: 'grass',
        category: 'status',
        pp: 15,
        power: null,
        accuracy: 100,
        effect: 'puts opponent to sleep.',
        id: '62f8d181cb1a94396dbd4719',
      },
      {
        _id: '62f8d181cb1a94396dbd471a',
        name: 'stomp',
        type: 'normal',
        category: 'physical',
        pp: 20,
        power: 65,
        accuracy: 100,
        effect: 'may cause flinching.',
        id: '62f8d181cb1a94396dbd471a',
      },
      {
        _id: '62f8d181cb1a94396dbd471b',
        hm: '04',
        name: 'strength',
        type: 'normal',
        category: 'physical',
        pp: 15,
        power: 80,
        accuracy: 100,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd471b',
      },
      {
        _id: '62f8d181cb1a94396dbd471c',
        name: 'string shot',
        type: 'bug',
        category: 'status',
        pp: 40,
        power: null,
        accuracy: 95,
        effect: "sharply lowers opponent's speed.",
        id: '62f8d181cb1a94396dbd471c',
      },
      {
        _id: '62f8d181cb1a94396dbd471d',
        name: 'struggle',
        type: 'normal',
        category: 'physical',
        pp: null,
        power: 50,
        accuracy: null,
        effect: 'only usable when all pp are gone. hurts the user.',
        id: '62f8d181cb1a94396dbd471d',
      },
      {
        _id: '62f8d181cb1a94396dbd471e',
        name: 'stun spore',
        type: 'grass',
        category: 'status',
        pp: 30,
        power: null,
        accuracy: 75,
        effect: 'paralyzes opponent.',
        id: '62f8d181cb1a94396dbd471e',
      },
      {
        _id: '62f8d181cb1a94396dbd471f',
        tm: '17',
        name: 'submission',
        type: 'fighting',
        category: 'physical',
        pp: 20,
        power: 80,
        accuracy: 80,
        effect: 'user receives recoil damage.',
        id: '62f8d181cb1a94396dbd471f',
      },
      {
        _id: '62f8d181cb1a94396dbd4720',
        tm: '50',
        name: 'substitute',
        type: 'normal',
        category: 'status',
        pp: 10,
        power: null,
        accuracy: null,
        effect: 'uses hp to creates a decoy that takes hits.',
        id: '62f8d181cb1a94396dbd4720',
      },
      {
        _id: '62f8d181cb1a94396dbd4721',
        name: 'super fang',
        type: 'normal',
        category: 'physical',
        pp: 10,
        power: null,
        accuracy: 90,
        effect: "always takes off half of the opponent's hp.",
        id: '62f8d181cb1a94396dbd4721',
      },
      {
        _id: '62f8d181cb1a94396dbd4722',
        name: 'supersonic',
        type: 'normal',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: 55,
        effect: 'confuses opponent.',
        id: '62f8d181cb1a94396dbd4722',
      },
      {
        _id: '62f8d181cb1a94396dbd4723',
        hm: '03',
        name: 'surf',
        type: 'water',
        category: 'special',
        pp: 15,
        power: 90,
        accuracy: 100,
        effect: 'hits all adjacent pokémon.',
        id: '62f8d181cb1a94396dbd4723',
      },
      {
        _id: '62f8d181cb1a94396dbd4724',
        tm: '39',
        name: 'swift',
        type: 'normal',
        category: 'special',
        pp: 20,
        power: 60,
        accuracy: null,
        effect: 'ignores accuracy and evasiveness.',
        id: '62f8d181cb1a94396dbd4724',
      },
      {
        _id: '62f8d181cb1a94396dbd4725',
        tm: '03',
        name: 'swords dance',
        type: 'normal',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: null,
        effect: "sharply raises user's attack.",
        id: '62f8d181cb1a94396dbd4725',
      },
      {
        _id: '62f8d181cb1a94396dbd4726',
        name: 'tackle',
        type: 'normal',
        category: 'physical',
        pp: 35,
        power: 40,
        accuracy: 100,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd4726',
      },
      {
        _id: '62f8d181cb1a94396dbd4727',
        name: 'tail whip',
        type: 'normal',
        category: 'status',
        pp: 30,
        power: null,
        accuracy: 100,
        effect: "lowers opponent's defense.",
        id: '62f8d181cb1a94396dbd4727',
      },
      {
        _id: '62f8d181cb1a94396dbd4728',
        tm: '09',
        name: 'take down',
        type: 'normal',
        category: 'physical',
        pp: 20,
        power: 90,
        accuracy: 85,
        effect: 'user receives recoil damage.',
        id: '62f8d181cb1a94396dbd4728',
      },
      {
        _id: '62f8d181cb1a94396dbd4729',
        tm: '30',
        name: 'teleport',
        type: 'psychic',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: null,
        effect: 'allows user to flee wild battles; also warps player to last pokécenter.',
        id: '62f8d181cb1a94396dbd4729',
      },
      {
        _id: '62f8d181cb1a94396dbd472a',
        name: 'thrash',
        type: 'normal',
        category: 'physical',
        pp: 10,
        power: 120,
        accuracy: 100,
        effect: 'user attacks for 2-3 turns but then becomes confused.',
        id: '62f8d181cb1a94396dbd472a',
      },
      {
        _id: '62f8d181cb1a94396dbd472b',
        tm: '25',
        name: 'thunder',
        type: 'electric',
        category: 'special',
        pp: 10,
        power: 110,
        accuracy: 70,
        effect: 'may paralyze opponent.',
        id: '62f8d181cb1a94396dbd472b',
      },
      {
        _id: '62f8d181cb1a94396dbd472c',
        name: 'thunder punch',
        type: 'electric',
        category: 'physical',
        pp: 15,
        power: 75,
        accuracy: 100,
        effect: 'may paralyze opponent.',
        id: '62f8d181cb1a94396dbd472c',
      },
      {
        _id: '62f8d181cb1a94396dbd472d',
        name: 'thunder shock',
        type: 'electric',
        category: 'special',
        pp: 30,
        power: 40,
        accuracy: 100,
        effect: 'may paralyze opponent.',
        id: '62f8d181cb1a94396dbd472d',
      },
      {
        _id: '62f8d181cb1a94396dbd472e',
        tm: '45',
        name: 'thunder wave',
        type: 'electric',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: 90,
        effect: 'paralyzes opponent.',
        id: '62f8d181cb1a94396dbd472e',
      },
      {
        _id: '62f8d181cb1a94396dbd472f',
        tm: '24',
        name: 'thunderbolt',
        type: 'electric',
        category: 'special',
        pp: 15,
        power: 90,
        accuracy: 100,
        effect: 'may paralyze opponent.',
        id: '62f8d181cb1a94396dbd472f',
      },
      {
        _id: '62f8d181cb1a94396dbd4730',
        tm: '06',
        name: 'toxic',
        type: 'poison',
        category: 'status',
        pp: 10,
        power: null,
        accuracy: 90,
        effect: 'badly poisons opponent.',
        id: '62f8d181cb1a94396dbd4730',
      },
      {
        _id: '62f8d181cb1a94396dbd4731',
        name: 'transform',
        type: 'normal',
        category: 'status',
        pp: 10,
        power: null,
        accuracy: null,
        effect: 'user takes on the form and attacks of the opponent.',
        id: '62f8d181cb1a94396dbd4731',
      },
      {
        _id: '62f8d181cb1a94396dbd4732',
        tm: '49',
        name: 'tri attack',
        type: 'normal',
        category: 'special',
        pp: 10,
        power: 80,
        accuracy: 100,
        effect: 'may paralyze, burn or freeze opponent.',
        id: '62f8d181cb1a94396dbd4732',
      },
      {
        _id: '62f8d181cb1a94396dbd4733',
        name: 'twineedle',
        type: 'bug',
        category: 'physical',
        pp: 20,
        power: 25,
        accuracy: 100,
        effect: 'hits twice in one turn. may poison opponent.',
        id: '62f8d181cb1a94396dbd4733',
      },
      {
        _id: '62f8d181cb1a94396dbd4734',
        name: 'vine whip',
        type: 'grass',
        category: 'physical',
        pp: 25,
        power: 45,
        accuracy: 100,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd4734',
      },
      {
        _id: '62f8d181cb1a94396dbd4735',
        name: 'vise grip',
        type: 'normal',
        category: 'physical',
        pp: 30,
        power: 55,
        accuracy: 100,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd4735',
      },
      {
        _id: '62f8d181cb1a94396dbd4736',
        tm: '12',
        name: 'water gun',
        type: 'water',
        category: 'special',
        pp: 25,
        power: 40,
        accuracy: 100,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd4736',
      },
      {
        _id: '62f8d181cb1a94396dbd4737',
        name: 'waterfall',
        type: 'water',
        category: 'physical',
        pp: 15,
        power: 80,
        accuracy: 100,
        effect: 'may cause flinching.',
        id: '62f8d181cb1a94396dbd4737',
      },
      {
        _id: '62f8d181cb1a94396dbd4738',
        tm: '04',
        name: 'whirlwind',
        type: 'normal',
        category: 'status',
        pp: 20,
        power: null,
        accuracy: null,
        effect: 'in battles, the opponent switches. in the wild, the pokémon runs.',
        id: '62f8d181cb1a94396dbd4738',
      },
      {
        _id: '62f8d181cb1a94396dbd4739',
        name: 'wing attack',
        type: 'flying',
        category: 'physical',
        pp: 35,
        power: 60,
        accuracy: 100,
        effect: 'deals damage with no additional effect.',
        id: '62f8d181cb1a94396dbd4739',
      },
      {
        _id: '62f8d181cb1a94396dbd473a',
        name: 'withdraw',
        type: 'water',
        category: 'status',
        pp: 40,
        power: null,
        accuracy: null,
        effect: "raises user's defense.",
        id: '62f8d181cb1a94396dbd473a',
      },
      {
        _id: '62f8d181cb1a94396dbd473b',
        name: 'wrap',
        type: 'normal',
        category: 'physical',
        pp: 20,
        power: 15,
        accuracy: 90,
        effect: 'traps opponent, damaging them for 4-5 turns.',
        id: '62f8d181cb1a94396dbd473b',
      },
    ],
  },
}`;

export default showMovesResponse;
