const pokemonTypes = [
  'bug',
  'dragon',
  'electric',
  'fightning',
  'fire',
  'flying',
  'ghost',
  'grass',
  'ground',
  'ice',
  'normal',
  'poison',
  'psychic',
  'rock',
  'water',
];

export default pokemonTypes;
