const showPokemonResponse = `{
  status: 'success',
  results: 151,
  data: {
    data: [
      {
        no: '001',
        name: 'bulbasaur',
        species: 'seed',
        type: ['grass', 'poison'],
        stats: {
          hp: 45,
          attack: 49,
          defense: 49,
          speed: 45,
          special: 65,
          total: 253,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/1.png',
          redgreen: '/static/img/sprites/red-green/1.png',
          yellow: '/static/img/sprites/yellow/1.png',
        },
      },
      {
        no: '002',
        name: 'ivysaur',
        species: 'seed',
        type: ['grass', 'poison'],
        stats: {
          hp: 60,
          attack: 62,
          defense: 63,
          speed: 60,
          special: 80,
          total: 325,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/2.png',
          redgreen: '/static/img/sprites/red-green/2.png',
          yellow: '/static/img/sprites/yellow/2.png',
        },
      },
      {
        no: '003',
        name: 'venusaur',
        species: 'seed',
        type: ['grass', 'poison'],
        stats: {
          hp: 80,
          attack: 82,
          defense: 83,
          speed: 80,
          special: 100,
          total: 425,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/3.png',
          redgreen: '/static/img/sprites/red-green/3.png',
          yellow: '/static/img/sprites/yellow/3.png',
        },
      },
      {
        no: '004',
        name: 'charmander',
        species: 'lizard',
        type: ['fire'],
        stats: {
          hp: 39,
          attack: 52,
          defense: 43,
          speed: 65,
          special: 50,
          total: 249,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/4.png',
          redgreen: '/static/img/sprites/red-green/4.png',
          yellow: '/static/img/sprites/yellow/4.png',
        },
      },
      {
        no: '005',
        name: 'charmeleon',
        species: 'flame',
        type: ['fire'],
        stats: {
          hp: 58,
          attack: 64,
          defense: 58,
          speed: 80,
          special: 65,
          total: 325,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/5.png',
          redgreen: '/static/img/sprites/red-green/5.png',
          yellow: '/static/img/sprites/yellow/5.png',
        },
      },
      {
        no: '006',
        name: 'charizard',
        species: 'flame',
        type: ['fire', 'flying'],
        stats: {
          hp: 78,
          attack: 84,
          defense: 78,
          speed: 100,
          special: 85,
          total: 425,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/6.png',
          redgreen: '/static/img/sprites/red-green/6.png',
          yellow: '/static/img/sprites/yellow/6.png',
        },
      },
      {
        no: '007',
        name: 'squirtle',
        species: 'tinyturtle',
        type: ['water'],
        stats: {
          hp: 44,
          attack: 48,
          defense: 65,
          speed: 43,
          special: 50,
          total: 250,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/7.png',
          redgreen: '/static/img/sprites/red-green/7.png',
          yellow: '/static/img/sprites/yellow/7.png',
        },
      },
      {
        no: '008',
        name: 'wartortle',
        species: 'turtle',
        type: ['water'],
        stats: {
          hp: 59,
          attack: 63,
          defense: 80,
          speed: 58,
          special: 65,
          total: 325,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/8.png',
          redgreen: '/static/img/sprites/red-green/8.png',
          yellow: '/static/img/sprites/yellow/8.png',
        },
      },
      {
        no: '009',
        name: 'blastoise',
        species: 'shellfish',
        type: ['water'],
        stats: {
          hp: 79,
          attack: 83,
          defense: 100,
          speed: 78,
          special: 85,
          total: 425,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/9.png',
          redgreen: '/static/img/sprites/red-green/9.png',
          yellow: '/static/img/sprites/yellow/9.png',
        },
      },
      {
        no: '010',
        name: 'caterpie',
        species: 'worm',
        type: ['bug'],
        stats: {
          hp: 45,
          attack: 30,
          defense: 35,
          speed: 45,
          special: 20,
          total: 175,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/10.png',
          redgreen: '/static/img/sprites/red-green/10.png',
          yellow: '/static/img/sprites/yellow/10.png',
        },
      },
      {
        no: '011',
        name: 'metapod',
        species: 'cocoon',
        type: ['bug'],
        stats: {
          hp: 50,
          attack: 20,
          defense: 55,
          speed: 30,
          special: 25,
          total: 180,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/11.png',
          redgreen: '/static/img/sprites/red-green/11.png',
          yellow: '/static/img/sprites/yellow/11.png',
        },
      },
      {
        no: '012',
        name: 'butterfree',
        species: 'butterfly',
        type: ['bug', 'flying'],
        stats: {
          hp: 60,
          attack: 45,
          defense: 50,
          speed: 70,
          special: 80,
          total: 305,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/12.png',
          redgreen: '/static/img/sprites/red-green/12.png',
          yellow: '/static/img/sprites/yellow/12.png',
        },
      },
      {
        no: '013',
        name: 'weedle',
        species: 'hairybug',
        type: ['bug', 'poison'],
        stats: {
          hp: 40,
          attack: 35,
          defense: 30,
          speed: 50,
          special: 20,
          total: 175,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/13.png',
          redgreen: '/static/img/sprites/red-green/13.png',
          yellow: '/static/img/sprites/yellow/13.png',
        },
      },
      {
        no: '014',
        name: 'kakuna',
        species: 'cocoon',
        type: ['bug', 'poison'],
        stats: {
          hp: 45,
          attack: 25,
          defense: 50,
          speed: 35,
          special: 25,
          total: 180,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/14.png',
          redgreen: '/static/img/sprites/red-green/14.png',
          yellow: '/static/img/sprites/yellow/14.png',
        },
      },
      {
        no: '015',
        name: 'beedrill',
        species: 'poisonbee',
        type: ['bug', 'poison'],
        stats: {
          hp: 65,
          attack: 80,
          defense: 40,
          speed: 75,
          special: 45,
          total: 305,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/15.png',
          redgreen: '/static/img/sprites/red-green/15.png',
          yellow: '/static/img/sprites/yellow/15.png',
        },
      },
      {
        no: '016',
        name: 'pidgey',
        species: 'tinybird',
        type: ['normal', 'flying'],
        stats: {
          hp: 40,
          attack: 45,
          defense: 40,
          speed: 56,
          special: 35,
          total: 216,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/16.png',
          redgreen: '/static/img/sprites/red-green/16.png',
          yellow: '/static/img/sprites/yellow/16.png',
        },
      },
      {
        no: '017',
        name: 'pidgeotto',
        species: 'bird',
        type: ['normal', 'flying'],
        stats: {
          hp: 63,
          attack: 60,
          defense: 55,
          speed: 71,
          special: 50,
          total: 299,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/17.png',
          redgreen: '/static/img/sprites/red-green/17.png',
          yellow: '/static/img/sprites/yellow/17.png',
        },
      },
      {
        no: '018',
        name: 'pidgeot',
        species: 'bird',
        type: ['normal', 'flying'],
        stats: {
          hp: 83,
          attack: 80,
          defense: 75,
          speed: 91,
          special: 70,
          total: 399,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/18.png',
          redgreen: '/static/img/sprites/red-green/18.png',
          yellow: '/static/img/sprites/yellow/18.png',
        },
      },
      {
        no: '019',
        name: 'rattata',
        species: 'mouse',
        type: ['normal'],
        stats: {
          hp: 30,
          attack: 56,
          defense: 35,
          speed: 72,
          special: 25,
          total: 218,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/19.png',
          redgreen: '/static/img/sprites/red-green/19.png',
          yellow: '/static/img/sprites/yellow/19.png',
        },
      },
      {
        no: '020',
        name: 'raticate',
        species: 'mouse',
        type: ['normal'],
        stats: {
          hp: 55,
          attack: 81,
          defense: 60,
          speed: 97,
          special: 50,
          total: 343,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/20.png',
          redgreen: '/static/img/sprites/red-green/20.png',
          yellow: '/static/img/sprites/yellow/20.png',
        },
      },
      {
        no: '021',
        name: 'spearow',
        species: 'tinybird',
        type: ['flying'],
        stats: {
          hp: 40,
          attack: 60,
          defense: 30,
          speed: 70,
          special: 31,
          total: 231,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/21.png',
          redgreen: '/static/img/sprites/red-green/21.png',
          yellow: '/static/img/sprites/yellow/21.png',
        },
      },
      {
        no: '022',
        name: 'fearow',
        species: 'beak',
        type: ['normal', 'flying'],
        stats: {
          hp: 65,
          attack: 90,
          defense: 65,
          speed: 100,
          special: 61,
          total: 381,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/22.png',
          redgreen: '/static/img/sprites/red-green/22.png',
          yellow: '/static/img/sprites/yellow/22.png',
        },
      },
      {
        no: '023',
        name: 'ekans',
        species: 'snake',
        type: ['poison'],
        stats: {
          hp: 35,
          attack: 60,
          defense: 44,
          speed: 55,
          special: 40,
          total: 234,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/23.png',
          redgreen: '/static/img/sprites/red-green/23.png',
          yellow: '/static/img/sprites/yellow/23.png',
        },
      },
      {
        no: '024',
        name: 'arbok',
        species: 'cobra',
        type: ['poison'],
        stats: {
          hp: 60,
          attack: 85,
          defense: 69,
          speed: 80,
          special: 65,
          total: 359,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/24.png',
          redgreen: '/static/img/sprites/red-green/24.png',
          yellow: '/static/img/sprites/yellow/24.png',
        },
      },
      {
        no: '025',
        name: 'pikachu',
        species: 'mouse',
        type: ['electric'],
        stats: {
          hp: 35,
          attack: 55,
          defense: 30,
          speed: 90,
          special: 50,
          total: 260,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/25.png',
          redgreen: '/static/img/sprites/red-green/25.png',
          yellow: '/static/img/sprites/yellow/25.png',
        },
      },
      {
        no: '026',
        name: 'raichu',
        species: 'mouse',
        type: ['electric'],
        stats: {
          hp: 60,
          attack: 90,
          defense: 55,
          speed: 100,
          special: 90,
          total: 395,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/26.png',
          redgreen: '/static/img/sprites/red-green/26.png',
          yellow: '/static/img/sprites/yellow/26.png',
        },
      },
      {
        no: '027',
        name: 'sandshrew',
        species: 'mouse',
        type: ['ground'],
        stats: {
          hp: 50,
          attack: 75,
          defense: 85,
          speed: 40,
          special: 30,
          total: 280,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/27.png',
          redgreen: '/static/img/sprites/red-green/27.png',
          yellow: '/static/img/sprites/yellow/27.png',
        },
      },
      {
        no: '028',
        name: 'sandslash',
        species: 'mouse',
        type: ['ground'],
        stats: {
          hp: 75,
          attack: 100,
          defense: 110,
          speed: 65,
          special: 55,
          total: 405,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/28.png',
          redgreen: '/static/img/sprites/red-green/28.png',
          yellow: '/static/img/sprites/yellow/28.png',
        },
      },
      {
        no: '029',
        name: 'nidoran-female',
        species: 'poisonpin',
        type: ['poison'],
        stats: {
          hp: 55,
          attack: 47,
          defense: 52,
          speed: 41,
          special: 40,
          total: 235,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/29.png',
          redgreen: '/static/img/sprites/red-green/29.png',
          yellow: '/static/img/sprites/yellow/29.png',
        },
      },
      {
        no: '030',
        name: 'nidorina',
        species: 'poisonpin',
        type: ['poison'],
        stats: {
          hp: 70,
          attack: 62,
          defense: 67,
          speed: 56,
          special: 55,
          total: 310,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/30.png',
          redgreen: '/static/img/sprites/red-green/30.png',
          yellow: '/static/img/sprites/yellow/30.png',
        },
      },
      {
        no: '031',
        name: 'nidoqueen',
        species: 'drill',
        type: ['poison', 'ground'],
        stats: {
          hp: 90,
          attack: 82,
          defense: 87,
          speed: 76,
          special: 75,
          total: 410,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/31.png',
          redgreen: '/static/img/sprites/red-green/31.png',
          yellow: '/static/img/sprites/yellow/31.png',
        },
      },
      {
        no: '032',
        name: 'nidoran-male',
        species: 'poisonpin',
        type: ['poison'],
        stats: {
          hp: 46,
          attack: 57,
          defense: 40,
          speed: 50,
          special: 40,
          total: 233,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/32.png',
          redgreen: '/static/img/sprites/red-green/32.png',
          yellow: '/static/img/sprites/yellow/32.png',
        },
      },
      {
        no: '033',
        name: 'nidorino',
        species: 'poisonpin',
        type: ['poison'],
        stats: {
          hp: 61,
          attack: 72,
          defense: 57,
          speed: 65,
          special: 55,
          total: 310,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/33.png',
          redgreen: '/static/img/sprites/red-green/33.png',
          yellow: '/static/img/sprites/yellow/33.png',
        },
      },
      {
        no: '034',
        name: 'nidoking',
        species: 'drill',
        type: ['poison', 'ground'],
        stats: {
          hp: 81,
          attack: 92,
          defense: 77,
          speed: 85,
          special: 75,
          total: 410,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/34.png',
          redgreen: '/static/img/sprites/red-green/34.png',
          yellow: '/static/img/sprites/yellow/34.png',
        },
      },
      {
        no: '035',
        name: 'clefairy',
        species: 'fairy',
        type: ['normal'],
        stats: {
          hp: 70,
          attack: 45,
          defense: 48,
          speed: 35,
          special: 60,
          total: 258,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/35.png',
          redgreen: '/static/img/sprites/red-green/35.png',
          yellow: '/static/img/sprites/yellow/35.png',
        },
      },
      {
        no: '036',
        name: 'clefable',
        species: 'fairy',
        type: ['normal'],
        stats: {
          hp: 95,
          attack: 70,
          defense: 73,
          speed: 60,
          special: 85,
          total: 383,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/36.png',
          redgreen: '/static/img/sprites/red-green/36.png',
          yellow: '/static/img/sprites/yellow/36.png',
        },
      },
      {
        no: '037',
        name: 'vulpix',
        species: 'fox',
        type: ['fire'],
        stats: {
          hp: 38,
          attack: 41,
          defense: 40,
          speed: 65,
          special: 65,
          total: 249,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/37.png',
          redgreen: '/static/img/sprites/red-green/37.png',
          yellow: '/static/img/sprites/yellow/37.png',
        },
      },
      {
        no: '038',
        name: 'ninetales',
        species: 'fox',
        type: ['fire'],
        stats: {
          hp: 73,
          attack: 76,
          defense: 75,
          speed: 100,
          special: 100,
          total: 424,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/38.png',
          redgreen: '/static/img/sprites/red-green/38.png',
          yellow: '/static/img/sprites/yellow/38.png',
        },
      },
      {
        no: '039',
        name: 'jigglypuff',
        species: 'balloon',
        type: ['normal'],
        stats: {
          hp: 115,
          attack: 45,
          defense: 20,
          speed: 20,
          special: 25,
          total: 225,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/39.png',
          redgreen: '/static/img/sprites/red-green/39.png',
          yellow: '/static/img/sprites/yellow/39.png',
        },
      },
      {
        no: '040',
        name: 'wigglytuff',
        species: 'balloon',
        type: ['normal'],
        stats: {
          hp: 140,
          attack: 70,
          defense: 45,
          speed: 45,
          special: 50,
          total: 350,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/40.png',
          redgreen: '/static/img/sprites/red-green/40.png',
          yellow: '/static/img/sprites/yellow/40.png',
        },
      },
      {
        no: '041',
        name: 'zubat',
        species: 'bat',
        type: ['poison', 'flying'],
        stats: {
          hp: 40,
          attack: 45,
          defense: 35,
          speed: 55,
          special: 40,
          total: 215,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/41.png',
          redgreen: '/static/img/sprites/red-green/41.png',
          yellow: '/static/img/sprites/yellow/41.png',
        },
      },
      {
        no: '042',
        name: 'golbat',
        species: 'bat',
        type: ['poison', 'flying'],
        stats: {
          hp: 75,
          attack: 80,
          defense: 70,
          speed: 90,
          special: 75,
          total: 390,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/42.png',
          redgreen: '/static/img/sprites/red-green/42.png',
          yellow: '/static/img/sprites/yellow/42.png',
        },
      },
      {
        no: '043',
        name: 'oddish',
        species: 'weed',
        type: ['grass', 'poison'],
        stats: {
          hp: 45,
          attack: 50,
          defense: 55,
          speed: 30,
          special: 75,
          total: 255,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/43.png',
          redgreen: '/static/img/sprites/red-green/43.png',
          yellow: '/static/img/sprites/yellow/43.png',
        },
      },
      {
        no: '044',
        name: 'gloom',
        species: 'weed',
        type: ['grass', 'poison'],
        stats: {
          hp: 60,
          attack: 65,
          defense: 70,
          speed: 40,
          special: 85,
          total: 320,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/44.png',
          redgreen: '/static/img/sprites/red-green/44.png',
          yellow: '/static/img/sprites/yellow/44.png',
        },
      },
      {
        no: '045',
        name: 'vileplume',
        species: 'flower',
        type: ['grass', 'poison'],
        stats: {
          hp: 75,
          attack: 80,
          defense: 85,
          speed: 50,
          special: 100,
          total: 390,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/45.png',
          redgreen: '/static/img/sprites/red-green/45.png',
          yellow: '/static/img/sprites/yellow/45.png',
        },
      },
      {
        no: '046',
        name: 'paras',
        species: 'mushroom',
        type: ['bug', 'grass'],
        stats: {
          hp: 35,
          attack: 70,
          defense: 55,
          speed: 25,
          special: 55,
          total: 240,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/46.png',
          redgreen: '/static/img/sprites/red-green/46.png',
          yellow: '/static/img/sprites/yellow/46.png',
        },
      },
      {
        no: '047',
        name: 'parasect',
        species: 'mushroom',
        type: ['bug', 'grass'],
        stats: {
          hp: 60,
          attack: 95,
          defense: 80,
          speed: 30,
          special: 80,
          total: 345,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/47.png',
          redgreen: '/static/img/sprites/red-green/47.png',
          yellow: '/static/img/sprites/yellow/47.png',
        },
      },
      {
        no: '048',
        name: 'venonat',
        species: 'insect',
        type: ['bug', 'poison'],
        stats: {
          hp: 60,
          attack: 55,
          defense: 50,
          speed: 45,
          special: 40,
          total: 250,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/48.png',
          redgreen: '/static/img/sprites/red-green/48.png',
          yellow: '/static/img/sprites/yellow/48.png',
        },
      },
      {
        no: '049',
        name: 'venomoth',
        species: 'poisonmoth',
        type: ['bug', 'poison'],
        stats: {
          hp: 70,
          attack: 65,
          defense: 60,
          speed: 90,
          special: 90,
          total: 375,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/49.png',
          redgreen: '/static/img/sprites/red-green/49.png',
          yellow: '/static/img/sprites/yellow/49.png',
        },
      },
      {
        no: '050',
        name: 'diglett',
        species: 'mole',
        type: ['ground'],
        stats: {
          hp: 10,
          attack: 55,
          defense: 25,
          speed: 95,
          special: 45,
          total: 230,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/50.png',
          redgreen: '/static/img/sprites/red-green/50.png',
          yellow: '/static/img/sprites/yellow/50.png',
        },
      },
      {
        no: '051',
        name: 'dugtrio',
        species: 'mole',
        type: ['ground'],
        stats: {
          hp: 35,
          attack: 80,
          defense: 50,
          speed: 120,
          special: 70,
          total: 355,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/51.png',
          redgreen: '/static/img/sprites/red-green/51.png',
          yellow: '/static/img/sprites/yellow/51.png',
        },
      },
      {
        no: '052',
        name: 'meowth',
        species: 'scratchcat',
        type: ['normal'],
        stats: {
          hp: 40,
          attack: 45,
          defense: 35,
          speed: 90,
          special: 40,
          total: 250,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/52.png',
          redgreen: '/static/img/sprites/red-green/52.png',
          yellow: '/static/img/sprites/yellow/52.png',
        },
      },
      {
        no: '053',
        name: 'persian',
        species: 'classycat',
        type: ['normal'],
        stats: {
          hp: 65,
          attack: 70,
          defense: 60,
          speed: 115,
          special: 65,
          total: 375,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/53.png',
          redgreen: '/static/img/sprites/red-green/53.png',
          yellow: '/static/img/sprites/yellow/53.png',
        },
      },
      {
        no: '054',
        name: 'psyduck',
        species: 'duck',
        type: ['water'],
        stats: {
          hp: 50,
          attack: 52,
          defense: 48,
          speed: 55,
          special: 50,
          total: 255,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/54.png',
          redgreen: '/static/img/sprites/red-green/54.png',
          yellow: '/static/img/sprites/yellow/54.png',
        },
      },
      {
        no: '055',
        name: 'golduck',
        species: 'duck',
        type: ['water'],
        stats: {
          hp: 80,
          attack: 82,
          defense: 78,
          speed: 85,
          special: 80,
          total: 405,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/55.png',
          redgreen: '/static/img/sprites/red-green/55.png',
          yellow: '/static/img/sprites/yellow/55.png',
        },
      },
      {
        no: '056',
        name: 'mankey',
        species: 'pigmonkey',
        type: ['fighting'],
        stats: {
          hp: 40,
          attack: 80,
          defense: 35,
          speed: 70,
          special: 35,
          total: 260,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/56.png',
          redgreen: '/static/img/sprites/red-green/56.png',
          yellow: '/static/img/sprites/yellow/56.png',
        },
      },
      {
        no: '057',
        name: 'primeape',
        species: 'pigmonkey',
        type: ['fighting'],
        stats: {
          hp: 65,
          attack: 105,
          defense: 60,
          speed: 95,
          special: 60,
          total: 385,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/57.png',
          redgreen: '/static/img/sprites/red-green/57.png',
          yellow: '/static/img/sprites/yellow/57.png',
        },
      },
      {
        no: '058',
        name: 'growlithe',
        species: 'puppy',
        type: ['fire'],
        stats: {
          hp: 55,
          attack: 70,
          defense: 45,
          speed: 60,
          special: 50,
          total: 280,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/58.png',
          redgreen: '/static/img/sprites/red-green/58.png',
          yellow: '/static/img/sprites/yellow/58.png',
        },
      },
      {
        no: '059',
        name: 'arcanine',
        species: 'legendary',
        type: ['fire'],
        stats: {
          hp: 90,
          attack: 110,
          defense: 80,
          speed: 95,
          special: 80,
          total: 455,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/59.png',
          redgreen: '/static/img/sprites/red-green/59.png',
          yellow: '/static/img/sprites/yellow/59.png',
        },
      },
      {
        no: '060',
        name: 'poliwag',
        species: 'tadpole',
        type: ['water'],
        stats: {
          hp: 40,
          attack: 50,
          defense: 40,
          speed: 90,
          special: 40,
          total: 260,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/60.png',
          redgreen: '/static/img/sprites/red-green/60.png',
          yellow: '/static/img/sprites/yellow/60.png',
        },
      },
      {
        no: '061',
        name: 'poliwhirl',
        species: 'tadpole',
        type: ['water'],
        stats: {
          hp: 65,
          attack: 65,
          defense: 65,
          speed: 90,
          special: 50,
          total: 335,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/61.png',
          redgreen: '/static/img/sprites/red-green/61.png',
          yellow: '/static/img/sprites/yellow/61.png',
        },
      },
      {
        no: '062',
        name: 'poliwrath',
        species: 'tadpole',
        type: ['water', 'fighting'],
        stats: {
          hp: 90,
          attack: 85,
          defense: 95,
          speed: 70,
          special: 70,
          total: 410,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/62.png',
          redgreen: '/static/img/sprites/red-green/62.png',
          yellow: '/static/img/sprites/yellow/62.png',
        },
      },
      {
        no: '063',
        name: 'abra',
        species: 'psi',
        type: ['psychic'],
        stats: {
          hp: 25,
          attack: 20,
          defense: 15,
          speed: 90,
          special: 105,
          total: 255,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/63.png',
          redgreen: '/static/img/sprites/red-green/63.png',
          yellow: '/static/img/sprites/yellow/63.png',
        },
      },
      {
        no: '064',
        name: 'kadabra',
        species: 'psi',
        type: ['psychic'],
        stats: {
          hp: 40,
          attack: 35,
          defense: 30,
          speed: 105,
          special: 120,
          total: 330,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/64.png',
          redgreen: '/static/img/sprites/red-green/64.png',
          yellow: '/static/img/sprites/yellow/64.png',
        },
      },
      {
        no: '065',
        name: 'alakazam',
        species: 'psi',
        type: ['psychic'],
        stats: {
          hp: 55,
          attack: 50,
          defense: 45,
          speed: 120,
          special: 135,
          total: 405,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/65.png',
          redgreen: '/static/img/sprites/red-green/65.png',
          yellow: '/static/img/sprites/yellow/65.png',
        },
      },
      {
        no: '066',
        name: 'machop',
        species: 'superpower',
        type: ['fighting'],
        stats: {
          hp: 70,
          attack: 80,
          defense: 50,
          speed: 35,
          special: 35,
          total: 270,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/66.png',
          redgreen: '/static/img/sprites/red-green/66.png',
          yellow: '/static/img/sprites/yellow/66.png',
        },
      },
      {
        no: '067',
        name: 'machoke',
        species: 'superpower',
        type: ['fighting'],
        stats: {
          hp: 80,
          attack: 100,
          defense: 70,
          speed: 45,
          special: 50,
          total: 345,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/67.png',
          redgreen: '/static/img/sprites/red-green/67.png',
          yellow: '/static/img/sprites/yellow/67.png',
        },
      },
      {
        no: '068',
        name: 'machamp',
        species: 'superpower',
        type: ['fighting'],
        stats: {
          hp: 90,
          attack: 130,
          defense: 80,
          speed: 55,
          special: 65,
          total: 420,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/68.png',
          redgreen: '/static/img/sprites/red-green/68.png',
          yellow: '/static/img/sprites/yellow/68.png',
        },
      },
      {
        no: '069',
        name: 'bellsprout',
        species: 'flower',
        type: ['grass', 'poison'],
        stats: {
          hp: 50,
          attack: 75,
          defense: 35,
          speed: 40,
          special: 70,
          total: 270,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/69.png',
          redgreen: '/static/img/sprites/red-green/69.png',
          yellow: '/static/img/sprites/yellow/69.png',
        },
      },
      {
        no: '070',
        name: 'weepinbell',
        species: 'flycatcher',
        type: ['grass', 'poison'],
        stats: {
          hp: 65,
          attack: 90,
          defense: 50,
          speed: 55,
          special: 85,
          total: 345,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/70.png',
          redgreen: '/static/img/sprites/red-green/70.png',
          yellow: '/static/img/sprites/yellow/70.png',
        },
      },
      {
        no: '071',
        name: 'victreebel',
        species: 'flycatcher',
        type: ['grass', 'poison'],
        stats: {
          hp: 80,
          attack: 105,
          defense: 65,
          speed: 70,
          special: 100,
          total: 420,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/71.png',
          redgreen: '/static/img/sprites/red-green/71.png',
          yellow: '/static/img/sprites/yellow/71.png',
        },
      },
      {
        no: '072',
        name: 'tentacool',
        species: 'jellyfish',
        type: ['water', 'poison'],
        stats: {
          hp: 40,
          attack: 40,
          defense: 35,
          speed: 70,
          special: 100,
          total: 285,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/72.png',
          redgreen: '/static/img/sprites/red-green/72.png',
          yellow: '/static/img/sprites/yellow/72.png',
        },
      },
      {
        no: '073',
        name: 'tentacruel',
        species: 'jellyfish',
        type: ['water', 'poison'],
        stats: {
          hp: 80,
          attack: 70,
          defense: 65,
          speed: 100,
          special: 120,
          total: 435,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/73.png',
          redgreen: '/static/img/sprites/red-green/73.png',
          yellow: '/static/img/sprites/yellow/73.png',
        },
      },
      {
        no: '074',
        name: 'geodude',
        species: 'rock',
        type: ['rock', 'ground'],
        stats: {
          hp: 40,
          attack: 80,
          defense: 100,
          speed: 20,
          special: 30,
          total: 270,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/74.png',
          redgreen: '/static/img/sprites/red-green/74.png',
          yellow: '/static/img/sprites/yellow/74.png',
        },
      },
      {
        no: '075',
        name: 'graveler',
        species: 'rock',
        type: ['rock', 'ground'],
        stats: {
          hp: 55,
          attack: 95,
          defense: 115,
          speed: 35,
          special: 45,
          total: 345,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/75.png',
          redgreen: '/static/img/sprites/red-green/75.png',
          yellow: '/static/img/sprites/yellow/75.png',
        },
      },
      {
        no: '076',
        name: 'golem',
        species: 'megaton',
        type: ['rock', 'ground'],
        stats: {
          hp: 80,
          attack: 110,
          defense: 130,
          speed: 45,
          special: 55,
          total: 420,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/76.png',
          redgreen: '/static/img/sprites/red-green/76.png',
          yellow: '/static/img/sprites/yellow/76.png',
        },
      },
      {
        no: '077',
        name: 'ponyta',
        species: 'firehorse',
        type: ['fire'],
        stats: {
          hp: 50,
          attack: 85,
          defense: 55,
          speed: 90,
          special: 65,
          total: 345,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/77.png',
          redgreen: '/static/img/sprites/red-green/77.png',
          yellow: '/static/img/sprites/yellow/77.png',
        },
      },
      {
        no: '078',
        name: 'rapidash',
        species: 'firehorse',
        type: ['fire'],
        stats: {
          hp: 65,
          attack: 100,
          defense: 70,
          speed: 105,
          special: 80,
          total: 420,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/78.png',
          redgreen: '/static/img/sprites/red-green/78.png',
          yellow: '/static/img/sprites/yellow/78.png',
        },
      },
      {
        no: '079',
        name: 'slowpoke',
        species: 'dopey',
        type: ['water', 'psychic'],
        stats: {
          hp: 90,
          attack: 65,
          defense: 65,
          speed: 15,
          special: 40,
          total: 275,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/79.png',
          redgreen: '/static/img/sprites/red-green/79.png',
          yellow: '/static/img/sprites/yellow/79.png',
        },
      },
      {
        no: '080',
        name: 'slowbro',
        species: 'hermitcrab',
        type: ['water', 'psychic'],
        stats: {
          hp: 95,
          attack: 75,
          defense: 110,
          speed: 30,
          special: 80,
          total: 390,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/80.png',
          redgreen: '/static/img/sprites/red-green/80.png',
          yellow: '/static/img/sprites/yellow/80.png',
        },
      },
      {
        no: '081',
        name: 'magnemite',
        species: 'magnet',
        type: ['electric'],
        stats: {
          hp: 25,
          attack: 35,
          defense: 70,
          speed: 45,
          special: 95,
          total: 270,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/81.png',
          redgreen: '/static/img/sprites/red-green/81.png',
          yellow: '/static/img/sprites/yellow/81.png',
        },
      },
      {
        no: '082',
        name: 'magneton',
        species: 'magnet',
        type: ['electric'],
        stats: {
          hp: 50,
          attack: 60,
          defense: 95,
          speed: 70,
          special: 120,
          total: 395,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/82.png',
          redgreen: '/static/img/sprites/red-green/82.png',
          yellow: '/static/img/sprites/yellow/82.png',
        },
      },
      {
        no: '083',
        name: 'farfetchd',
        species: 'wildduck',
        type: ['normal', 'flying'],
        stats: {
          hp: 52,
          attack: 65,
          defense: 55,
          speed: 60,
          special: 58,
          total: 290,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/83.png',
          redgreen: '/static/img/sprites/red-green/83.png',
          yellow: '/static/img/sprites/yellow/83.png',
        },
      },
      {
        no: '084',
        name: 'doduo',
        species: 'twinbird',
        type: ['normal', 'flying'],
        stats: {
          hp: 35,
          attack: 85,
          defense: 45,
          speed: 75,
          special: 35,
          total: 275,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/84.png',
          redgreen: '/static/img/sprites/red-green/84.png',
          yellow: '/static/img/sprites/yellow/84.png',
        },
      },
      {
        no: '085',
        name: 'dodrio',
        species: 'triplebird',
        type: ['normal', 'flying'],
        stats: {
          hp: 60,
          attack: 110,
          defense: 70,
          speed: 100,
          special: 60,
          total: 400,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/85.png',
          redgreen: '/static/img/sprites/red-green/85.png',
          yellow: '/static/img/sprites/yellow/85.png',
        },
      },
      {
        no: '086',
        name: 'seel',
        species: 'sealion',
        type: ['water'],
        stats: {
          hp: 65,
          attack: 45,
          defense: 55,
          speed: 45,
          special: 70,
          total: 280,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/86.png',
          redgreen: '/static/img/sprites/red-green/86.png',
          yellow: '/static/img/sprites/yellow/86.png',
        },
      },
      {
        no: '087',
        name: 'dewgong',
        species: 'sealion',
        type: ['water', 'ice'],
        stats: {
          hp: 90,
          attack: 70,
          defense: 80,
          speed: 70,
          special: 95,
          total: 405,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/87.png',
          redgreen: '/static/img/sprites/red-green/87.png',
          yellow: '/static/img/sprites/yellow/87.png',
        },
      },
      {
        no: '088',
        name: 'grimer',
        species: 'sludge',
        type: ['poison'],
        stats: {
          hp: 80,
          attack: 80,
          defense: 50,
          speed: 25,
          special: 40,
          total: 275,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/88.png',
          redgreen: '/static/img/sprites/red-green/88.png',
          yellow: '/static/img/sprites/yellow/88.png',
        },
      },
      {
        no: '089',
        name: 'muk',
        species: 'sludge',
        type: ['poison'],
        stats: {
          hp: 105,
          attack: 105,
          defense: 75,
          speed: 50,
          special: 65,
          total: 400,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/89.png',
          redgreen: '/static/img/sprites/red-green/89.png',
          yellow: '/static/img/sprites/yellow/89.png',
        },
      },
      {
        no: '090',
        name: 'shellder',
        species: 'bivalve',
        type: ['water'],
        stats: {
          hp: 30,
          attack: 65,
          defense: 100,
          speed: 40,
          special: 45,
          total: 280,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/90.png',
          redgreen: '/static/img/sprites/red-green/90.png',
          yellow: '/static/img/sprites/yellow/90.png',
        },
      },
      {
        no: '091',
        name: 'cloyster',
        species: 'bivalve',
        type: ['water', 'ice'],
        stats: {
          hp: 50,
          attack: 95,
          defense: 180,
          speed: 70,
          special: 85,
          total: 480,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/91.png',
          redgreen: '/static/img/sprites/red-green/91.png',
          yellow: '/static/img/sprites/yellow/91.png',
        },
      },
      {
        no: '092',
        name: 'gastly',
        species: 'gas',
        type: ['ghost', 'poison'],
        stats: {
          hp: 30,
          attack: 35,
          defense: 30,
          speed: 80,
          special: 100,
          total: 275,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/92.png',
          redgreen: '/static/img/sprites/red-green/92.png',
          yellow: '/static/img/sprites/yellow/92.png',
        },
      },
      {
        no: '093',
        name: 'haunter',
        species: 'gas',
        type: ['ghost', 'poison'],
        stats: {
          hp: 45,
          attack: 50,
          defense: 45,
          speed: 95,
          special: 115,
          total: 350,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/93.png',
          redgreen: '/static/img/sprites/red-green/93.png',
          yellow: '/static/img/sprites/yellow/93.png',
        },
      },
      {
        no: '094',
        name: 'gengar',
        species: 'shadow',
        type: ['ghost', 'poison'],
        stats: {
          hp: 60,
          attack: 65,
          defense: 60,
          speed: 110,
          special: 130,
          total: 425,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/94.png',
          redgreen: '/static/img/sprites/red-green/94.png',
          yellow: '/static/img/sprites/yellow/94.png',
        },
      },
      {
        no: '095',
        name: 'onix',
        species: 'rocksnake',
        type: ['rock', 'ground'],
        stats: {
          hp: 35,
          attack: 45,
          defense: 160,
          speed: 70,
          special: 30,
          total: 340,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/95.png',
          redgreen: '/static/img/sprites/red-green/95.png',
          yellow: '/static/img/sprites/yellow/95.png',
        },
      },
      {
        no: '096',
        name: 'drowzee',
        species: 'hypnosis',
        type: ['psychic'],
        stats: {
          hp: 60,
          attack: 48,
          defense: 45,
          speed: 42,
          special: 90,
          total: 285,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/96.png',
          redgreen: '/static/img/sprites/red-green/96.png',
          yellow: '/static/img/sprites/yellow/96.png',
        },
      },
      {
        no: '097',
        name: 'hypno',
        species: 'hypnosis',
        type: ['psychic'],
        stats: {
          hp: 85,
          attack: 73,
          defense: 70,
          speed: 67,
          special: 115,
          total: 410,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/97.png',
          redgreen: '/static/img/sprites/red-green/97.png',
          yellow: '/static/img/sprites/yellow/97.png',
        },
      },
      {
        no: '098',
        name: 'krabby',
        species: 'rivercrab',
        type: ['water'],
        stats: {
          hp: 30,
          attack: 105,
          defense: 90,
          speed: 50,
          special: 25,
          total: 300,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/98.png',
          redgreen: '/static/img/sprites/red-green/98.png',
          yellow: '/static/img/sprites/yellow/98.png',
        },
      },
      {
        no: '099',
        name: 'kingler',
        species: 'pincer',
        type: ['water'],
        stats: {
          hp: 55,
          attack: 130,
          defense: 115,
          speed: 75,
          special: 50,
          total: 425,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/99.png',
          redgreen: '/static/img/sprites/red-green/99.png',
          yellow: '/static/img/sprites/yellow/99.png',
        },
      },
      {
        no: '100',
        name: 'voltorb',
        species: 'ball',
        type: ['electric'],
        stats: {
          hp: 40,
          attack: 30,
          defense: 50,
          speed: 100,
          special: 55,
          total: 275,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/100.png',
          redgreen: '/static/img/sprites/red-green/100.png',
          yellow: '/static/img/sprites/yellow/100.png',
        },
      },
      {
        no: '101',
        name: 'electrode',
        species: 'ball',
        type: ['electric'],
        stats: {
          hp: 60,
          attack: 50,
          defense: 70,
          speed: 140,
          special: 80,
          total: 400,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/101.png',
          redgreen: '/static/img/sprites/red-green/101.png',
          yellow: '/static/img/sprites/yellow/101.png',
        },
      },
      {
        no: '102',
        name: 'exeggcute',
        species: 'egg',
        type: ['grass', 'psychic'],
        stats: {
          hp: 60,
          attack: 40,
          defense: 80,
          speed: 40,
          special: 60,
          total: 280,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/102.png',
          redgreen: '/static/img/sprites/red-green/102.png',
          yellow: '/static/img/sprites/yellow/102.png',
        },
      },
      {
        no: '103',
        name: 'exeggutor',
        species: 'coconut',
        type: ['grass', 'psychic'],
        stats: {
          hp: 95,
          attack: 95,
          defense: 85,
          speed: 55,
          special: 125,
          total: 455,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/103.png',
          redgreen: '/static/img/sprites/red-green/103.png',
          yellow: '/static/img/sprites/yellow/103.png',
        },
      },
      {
        no: '104',
        name: 'cubone',
        species: 'lonely',
        type: ['ground'],
        stats: {
          hp: 50,
          attack: 50,
          defense: 95,
          speed: 35,
          special: 40,
          total: 270,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/104.png',
          redgreen: '/static/img/sprites/red-green/104.png',
          yellow: '/static/img/sprites/yellow/104.png',
        },
      },
      {
        no: '105',
        name: 'marowak',
        species: 'bonekeeper',
        type: ['ground'],
        stats: {
          hp: 60,
          attack: 80,
          defense: 110,
          speed: 45,
          special: 50,
          total: 345,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/105.png',
          redgreen: '/static/img/sprites/red-green/105.png',
          yellow: '/static/img/sprites/yellow/105.png',
        },
      },
      {
        no: '106',
        name: 'hitmonlee',
        species: 'kicking',
        type: ['fighting'],
        stats: {
          hp: 50,
          attack: 120,
          defense: 53,
          speed: 87,
          special: 35,
          total: 345,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/106.png',
          redgreen: '/static/img/sprites/red-green/106.png',
          yellow: '/static/img/sprites/yellow/106.png',
        },
      },
      {
        no: '107',
        name: 'hitmonchan',
        species: 'punching',
        type: ['fighting'],
        stats: {
          hp: 50,
          attack: 105,
          defense: 79,
          speed: 76,
          special: 35,
          total: 345,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/107.png',
          redgreen: '/static/img/sprites/red-green/107.png',
          yellow: '/static/img/sprites/yellow/107.png',
        },
      },
      {
        no: '108',
        name: 'lickitung',
        species: 'licking',
        type: ['normal'],
        stats: {
          hp: 90,
          attack: 55,
          defense: 75,
          speed: 30,
          special: 60,
          total: 310,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/108.png',
          redgreen: '/static/img/sprites/red-green/108.png',
          yellow: '/static/img/sprites/yellow/108.png',
        },
      },
      {
        no: '109',
        name: 'koffing',
        species: 'poisongas',
        type: ['poison'],
        stats: {
          hp: 40,
          attack: 65,
          defense: 95,
          speed: 35,
          special: 60,
          total: 295,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/109.png',
          redgreen: '/static/img/sprites/red-green/109.png',
          yellow: '/static/img/sprites/yellow/109.png',
        },
      },
      {
        no: '110',
        name: 'weezing',
        species: 'poisongas',
        type: ['poison'],
        stats: {
          hp: 65,
          attack: 90,
          defense: 120,
          speed: 60,
          special: 85,
          total: 420,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/110.png',
          redgreen: '/static/img/sprites/red-green/110.png',
          yellow: '/static/img/sprites/yellow/110.png',
        },
      },
      {
        no: '111',
        name: 'rhyhorn',
        species: 'spikes',
        type: ['ground', 'rock'],
        stats: {
          hp: 80,
          attack: 85,
          defense: 95,
          speed: 25,
          special: 30,
          total: 315,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/111.png',
          redgreen: '/static/img/sprites/red-green/111.png',
          yellow: '/static/img/sprites/yellow/111.png',
        },
      },
      {
        no: '112',
        name: 'rhydon',
        species: 'drill',
        type: ['ground', 'rock'],
        stats: {
          hp: 105,
          attack: 130,
          defense: 120,
          speed: 40,
          special: 45,
          total: 440,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/112.png',
          redgreen: '/static/img/sprites/red-green/112.png',
          yellow: '/static/img/sprites/yellow/112.png',
        },
      },
      {
        no: '113',
        name: 'chansey',
        species: 'egg',
        type: ['normal'],
        stats: {
          hp: 250,
          attack: 5,
          defense: 5,
          speed: 50,
          special: 105,
          total: 415,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/113.png',
          redgreen: '/static/img/sprites/red-green/113.png',
          yellow: '/static/img/sprites/yellow/113.png',
        },
      },
      {
        no: '114',
        name: 'tangela',
        species: 'vine',
        type: ['grass'],
        stats: {
          hp: 65,
          attack: 55,
          defense: 115,
          speed: 60,
          special: 100,
          total: 395,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/114.png',
          redgreen: '/static/img/sprites/red-green/114.png',
          yellow: '/static/img/sprites/yellow/114.png',
        },
      },
      {
        no: '115',
        name: 'kangaskhan',
        species: 'parent',
        type: ['normal'],
        stats: {
          hp: 105,
          attack: 95,
          defense: 80,
          speed: 90,
          special: 40,
          total: 410,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/115.png',
          redgreen: '/static/img/sprites/red-green/115.png',
          yellow: '/static/img/sprites/yellow/115.png',
        },
      },
      {
        no: '116',
        name: 'horsea',
        species: 'dragon',
        type: ['water'],
        stats: {
          hp: 30,
          attack: 40,
          defense: 70,
          speed: 60,
          special: 70,
          total: 270,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/116.png',
          redgreen: '/static/img/sprites/red-green/116.png',
          yellow: '/static/img/sprites/yellow/116.png',
        },
      },
      {
        no: '117',
        name: 'seadra',
        species: 'dragon',
        type: ['water'],
        stats: {
          hp: 55,
          attack: 65,
          defense: 95,
          speed: 85,
          special: 95,
          total: 395,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/117.png',
          redgreen: '/static/img/sprites/red-green/117.png',
          yellow: '/static/img/sprites/yellow/117.png',
        },
      },
      {
        no: '118',
        name: 'goldeen',
        species: 'goldfish',
        type: ['water'],
        stats: {
          hp: 45,
          attack: 67,
          defense: 60,
          speed: 63,
          special: 50,
          total: 285,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/118.png',
          redgreen: '/static/img/sprites/red-green/118.png',
          yellow: '/static/img/sprites/yellow/118.png',
        },
      },
      {
        no: '119',
        name: 'seaking',
        species: 'goldfish',
        type: ['water'],
        stats: {
          hp: 80,
          attack: 92,
          defense: 65,
          speed: 68,
          special: 80,
          total: 385,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/119.png',
          redgreen: '/static/img/sprites/red-green/119.png',
          yellow: '/static/img/sprites/yellow/119.png',
        },
      },
      {
        no: '120',
        name: 'staryu',
        species: 'starshape',
        type: ['water'],
        stats: {
          hp: 30,
          attack: 45,
          defense: 55,
          speed: 85,
          special: 70,
          total: 285,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/120.png',
          redgreen: '/static/img/sprites/red-green/120.png',
          yellow: '/static/img/sprites/yellow/120.png',
        },
      },
      {
        no: '121',
        name: 'starmie',
        species: 'mysterious',
        type: ['water', 'psychic'],
        stats: {
          hp: 60,
          attack: 75,
          defense: 85,
          speed: 115,
          special: 100,
          total: 435,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/121.png',
          redgreen: '/static/img/sprites/red-green/121.png',
          yellow: '/static/img/sprites/yellow/121.png',
        },
      },
      {
        no: '122',
        name: 'mr-mime',
        species: 'barrier',
        type: ['psychic'],
        stats: {
          hp: 40,
          attack: 45,
          defense: 65,
          speed: 90,
          special: 100,
          total: 340,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/122.png',
          redgreen: '/static/img/sprites/red-green/122.png',
          yellow: '/static/img/sprites/yellow/122.png',
        },
      },
      {
        no: '123',
        name: 'scyther',
        species: 'mantis',
        type: ['bug', 'flying'],
        stats: {
          hp: 70,
          attack: 110,
          defense: 80,
          speed: 105,
          special: 55,
          total: 420,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/123.png',
          redgreen: '/static/img/sprites/red-green/123.png',
          yellow: '/static/img/sprites/yellow/123.png',
        },
      },
      {
        no: '124',
        name: 'jynx',
        species: 'humanshape',
        type: ['ice', 'psychic'],
        stats: {
          hp: 65,
          attack: 50,
          defense: 35,
          speed: 95,
          special: 95,
          total: 340,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/124.png',
          redgreen: '/static/img/sprites/red-green/124.png',
          yellow: '/static/img/sprites/yellow/124.png',
        },
      },
      {
        no: '125',
        name: 'electabuzz',
        species: 'electric',
        type: ['electric'],
        stats: {
          hp: 65,
          attack: 83,
          defense: 57,
          speed: 105,
          special: 85,
          total: 395,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/125.png',
          redgreen: '/static/img/sprites/red-green/125.png',
          yellow: '/static/img/sprites/yellow/125.png',
        },
      },
      {
        no: '126',
        name: 'magmar',
        species: 'spitfire',
        type: ['fire'],
        stats: {
          hp: 65,
          attack: 95,
          defense: 57,
          speed: 93,
          special: 85,
          total: 395,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/126.png',
          redgreen: '/static/img/sprites/red-green/126.png',
          yellow: '/static/img/sprites/yellow/126.png',
        },
      },
      {
        no: '127',
        name: 'pinsir',
        species: 'stagbeetle',
        type: ['bug'],
        stats: {
          hp: 65,
          attack: 125,
          defense: 100,
          speed: 85,
          special: 55,
          total: 430,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/127.png',
          redgreen: '/static/img/sprites/red-green/127.png',
          yellow: '/static/img/sprites/yellow/127.png',
        },
      },
      {
        no: '128',
        name: 'tauros',
        species: 'wildbull',
        type: ['normal'],
        stats: {
          hp: 75,
          attack: 100,
          defense: 95,
          speed: 110,
          special: 70,
          total: 450,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/128.png',
          redgreen: '/static/img/sprites/red-green/128.png',
          yellow: '/static/img/sprites/yellow/128.png',
        },
      },
      {
        no: '129',
        name: 'magikarp',
        species: 'fish',
        type: ['water'],
        stats: {
          hp: 20,
          attack: 10,
          defense: 55,
          speed: 80,
          special: 20,
          total: 185,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/129.png',
          redgreen: '/static/img/sprites/red-green/129.png',
          yellow: '/static/img/sprites/yellow/129.png',
        },
      },
      {
        no: '130',
        name: 'gyarados',
        species: 'atrocious',
        type: ['water', 'flying'],
        stats: {
          hp: 95,
          attack: 125,
          defense: 79,
          speed: 81,
          special: 100,
          total: 480,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/130.png',
          redgreen: '/static/img/sprites/red-green/130.png',
          yellow: '/static/img/sprites/yellow/130.png',
        },
      },
      {
        no: '131',
        name: 'lapras',
        species: 'transport',
        type: ['water', 'ice'],
        stats: {
          hp: 130,
          attack: 85,
          defense: 80,
          speed: 60,
          special: 95,
          total: 450,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/131.png',
          redgreen: '/static/img/sprites/red-green/131.png',
          yellow: '/static/img/sprites/yellow/131.png',
        },
      },
      {
        no: '132',
        name: 'ditto',
        species: 'transform',
        type: ['normal'],
        stats: {
          hp: 48,
          attack: 48,
          defense: 48,
          speed: 48,
          special: 48,
          total: 240,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/132.png',
          redgreen: '/static/img/sprites/red-green/132.png',
          yellow: '/static/img/sprites/yellow/132.png',
        },
      },
      {
        no: '133',
        name: 'eevee',
        species: 'evolution',
        type: ['normal'],
        stats: {
          hp: 55,
          attack: 55,
          defense: 50,
          speed: 55,
          special: 65,
          total: 280,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/133.png',
          redgreen: '/static/img/sprites/red-green/133.png',
          yellow: '/static/img/sprites/yellow/133.png',
        },
      },
      {
        no: '134',
        name: 'vaporeon',
        species: 'bubblejet',
        type: ['water'],
        stats: {
          hp: 130,
          attack: 65,
          defense: 60,
          speed: 65,
          special: 110,
          total: 430,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/134.png',
          redgreen: '/static/img/sprites/red-green/134.png',
          yellow: '/static/img/sprites/yellow/134.png',
        },
      },
      {
        no: '135',
        name: 'jolteon',
        species: 'lightning',
        type: ['electric'],
        stats: {
          hp: 65,
          attack: 65,
          defense: 60,
          speed: 130,
          special: 110,
          total: 430,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/135.png',
          redgreen: '/static/img/sprites/red-green/135.png',
          yellow: '/static/img/sprites/yellow/135.png',
        },
      },
      {
        no: '136',
        name: 'flareon',
        species: 'flame',
        type: ['fire'],
        stats: {
          hp: 65,
          attack: 130,
          defense: 60,
          speed: 65,
          special: 110,
          total: 430,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/136.png',
          redgreen: '/static/img/sprites/red-green/136.png',
          yellow: '/static/img/sprites/yellow/136.png',
        },
      },
      {
        no: '137',
        name: 'porygon',
        species: 'virtual',
        type: ['normal'],
        stats: {
          hp: 65,
          attack: 60,
          defense: 70,
          speed: 40,
          special: 75,
          total: 310,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/137.png',
          redgreen: '/static/img/sprites/red-green/137.png',
          yellow: '/static/img/sprites/yellow/137.png',
        },
      },
      {
        no: '138',
        name: 'omanyte',
        species: 'spiral',
        type: ['rock', 'water'],
        stats: {
          hp: 35,
          attack: 40,
          defense: 100,
          speed: 35,
          special: 90,
          total: 300,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/138.png',
          redgreen: '/static/img/sprites/red-green/138.png',
          yellow: '/static/img/sprites/yellow/138.png',
        },
      },
      {
        no: '139',
        name: 'omastar',
        species: 'spiral',
        type: ['rock', 'water'],
        stats: {
          hp: 70,
          attack: 60,
          defense: 125,
          speed: 55,
          special: 115,
          total: 425,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/139.png',
          redgreen: '/static/img/sprites/red-green/139.png',
          yellow: '/static/img/sprites/yellow/139.png',
        },
      },
      {
        no: '140',
        name: 'kabuto',
        species: 'shellfish',
        type: ['rock', 'water'],
        stats: {
          hp: 30,
          attack: 80,
          defense: 90,
          speed: 55,
          special: 45,
          total: 300,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/140.png',
          redgreen: '/static/img/sprites/red-green/140.png',
          yellow: '/static/img/sprites/yellow/140.png',
        },
      },
      {
        no: '141',
        name: 'kabutops',
        species: 'shellfish',
        type: ['rock', 'water'],
        stats: {
          hp: 60,
          attack: 115,
          defense: 105,
          speed: 80,
          special: 70,
          total: 430,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/141.png',
          redgreen: '/static/img/sprites/red-green/141.png',
          yellow: '/static/img/sprites/yellow/141.png',
        },
      },
      {
        no: '142',
        name: 'aerodactyl',
        species: 'fossil',
        type: ['rock', 'flying'],
        stats: {
          hp: 80,
          attack: 105,
          defense: 65,
          speed: 130,
          special: 60,
          total: 440,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/142.png',
          redgreen: '/static/img/sprites/red-green/142.png',
          yellow: '/static/img/sprites/yellow/142.png',
        },
      },
      {
        no: '143',
        name: 'snorlax',
        species: 'sleeping',
        type: ['normal'],
        stats: {
          hp: 160,
          attack: 110,
          defense: 65,
          speed: 30,
          special: 65,
          total: 430,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/143.png',
          redgreen: '/static/img/sprites/red-green/143.png',
          yellow: '/static/img/sprites/yellow/143.png',
        },
      },
      {
        no: '144',
        name: 'articuno',
        species: 'freeze',
        type: ['ice', 'flying'],
        stats: {
          hp: 90,
          attack: 85,
          defense: 100,
          speed: 85,
          special: 125,
          total: 485,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/144.png',
          redgreen: '/static/img/sprites/red-green/144.png',
          yellow: '/static/img/sprites/yellow/144.png',
        },
      },
      {
        no: '145',
        name: 'zapdos',
        species: 'electric',
        type: ['electric', 'flying'],
        stats: {
          hp: 90,
          attack: 90,
          defense: 85,
          speed: 100,
          special: 125,
          total: 490,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/145.png',
          redgreen: '/static/img/sprites/red-green/145.png',
          yellow: '/static/img/sprites/yellow/145.png',
        },
      },
      {
        no: '146',
        name: 'moltres',
        species: 'flame',
        type: ['fire', 'flying'],
        stats: {
          hp: 90,
          attack: 100,
          defense: 90,
          speed: 90,
          special: 125,
          total: 495,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/146.png',
          redgreen: '/static/img/sprites/red-green/146.png',
          yellow: '/static/img/sprites/yellow/146.png',
        },
      },
      {
        no: '147',
        name: 'dratini',
        species: 'dragon',
        type: ['dragon'],
        stats: {
          hp: 41,
          attack: 64,
          defense: 45,
          speed: 50,
          special: 50,
          total: 250,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/147.png',
          redgreen: '/static/img/sprites/red-green/147.png',
          yellow: '/static/img/sprites/yellow/147.png',
        },
      },
      {
        no: '148',
        name: 'dragonair',
        species: 'dragon',
        type: ['dragon'],
        stats: {
          hp: 61,
          attack: 84,
          defense: 65,
          speed: 70,
          special: 70,
          total: 350,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/148.png',
          redgreen: '/static/img/sprites/red-green/148.png',
          yellow: '/static/img/sprites/yellow/148.png',
        },
      },
      {
        no: '149',
        name: 'dragonite',
        species: 'dragon',
        type: ['dragon', 'flying'],
        stats: {
          hp: 91,
          attack: 134,
          defense: 95,
          speed: 80,
          special: 100,
          total: 500,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/149.png',
          redgreen: '/static/img/sprites/red-green/149.png',
          yellow: '/static/img/sprites/yellow/149.png',
        },
      },
      {
        no: '150',
        name: 'mewtwo',
        species: 'genetic',
        type: ['psychic'],
        stats: {
          hp: 106,
          attack: 110,
          defense: 90,
          speed: 130,
          special: 154,
          total: 590,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/150.png',
          redgreen: '/static/img/sprites/red-green/150.png',
          yellow: '/static/img/sprites/yellow/150.png',
        },
      },
      {
        no: '151',
        name: 'mew',
        species: 'newspecies',
        type: ['psychic'],
        stats: {
          hp: 100,
          attack: 100,
          defense: 100,
          speed: 100,
          special: 100,
          total: 500,
        },
        sprite: {
          redblue: '/static/img/sprites/red-blue/151.png',
          redgreen: '/static/img/sprites/red-green/151.png',
          yellow: '/static/img/sprites/yellow/151.png',
        },
      },
    ],
  },
}`;

export default showPokemonResponse;
